import { ButtonPresets } from "../../UI/Button";
import { FormDataType } from "../../../screens/RegisterScreen/Views/RegisterScreenView";
import { InputPresets } from "../../UI/Input";
import { SelectPresets } from "../../UI/Select";
import { TextPresets } from "../../UI/Text";
import styled from "styled-components";
import { useEffect, useState, useRef } from "react";
import cn from "classnames";
import { Checkbox } from "../../UI";

import "react-datepicker/dist/react-datepicker.css";
import { DateInput } from "../../UI/Input/presets/DateInput";
import { Modal } from "@mui/material";
import { ButtonFilledPrimary } from "../../UI/Button/presets";
import Api from "../../../config/Api";
//import { ReCAPTCHA } from "react-google-recaptcha";

export interface DetailsStepProps {
  handleNext: () => void;
  onChange: (value: unknown, field: keyof FormDataType) => void;
  formData: FormDataType;
}

const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-Input {
        width: 100%;
        margin: 0.5rem 0;
      }
      &-InputTwo {
        width: 100%;
      }
    }

    &__Actions {
      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }

    .SelectInputRounded {
      color: #6e6b6b;
      margin-top: 0.8rem;
    }
  }
`;

interface CompanyDetailsData {
  name: string;
  birthday: string;
  phone: string;
  country?: any;
}

const initialFormData = {
  name: "",
  birthday: "",
  phone: "",
  country: "",
  terms: "",
};

export const DetailsStep = ({
  handleNext,
  onChange,
  formData,
  ...props
}: DetailsStepProps) => {
  const [error, setError] = useState(initialFormData);
  const [sendingForm, setSendingForm] = useState(false);

  const [data] = useState<CompanyDetailsData>({
    name: "",
    birthday: "",
    phone: "",
    country: "",
  });

  const handleSubmit = async () => {
    if (sendingForm) return;
    setSendingForm(true);

    if (formData.detailsStep.name.trim() === "") {
      setError({ ...error, name: "Name is required" });
      setSendingForm(false);

      return;
    }

    if (formData.detailsStep.birthday.trim() === "") {
      setError({ ...error, birthday: "Birthday is required" });
      setSendingForm(false);

      return;
    }

    if (formData.detailsStep.phone.trim() === "") {
      setError({
        ...error,
        phone: "Phone is required",
      });
      setSendingForm(false);

      return;
    }

    if (formData.detailsStep.country.trim() === "") {
      setError({
        ...error,
        country: "Country is required",
      });
      setSendingForm(false);

      return;
    }

    // Comprueba si los términos y condiciones están aceptados
    if (termsAccepted) {
      // Si no están aceptados, puedes mostrar un mensaje de error o realizar alguna acción
      // setError({
      //   ...error,
      //   terms: "You must accept the Terms & Conditions",
      // });
      setTermsModalOpen(true);
      setSendingForm(false);

      return;
    }

    //! envar el token de recaptcha al backend para validar

    const { data } = await Api.post(
      "https://api.crm.filtroo.co/recaptcha/verify",
      {
        token: recaptchaToken,
      }
    );
    if (data.success && data.score > 0.5) {
      handleNext();
    } else {
      setError({ ...error, terms: "reCAPTCHA verification failed" });
      setSendingForm(false);
    }
  };

  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [hasReadContract, setHasReadContract] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const recaptchaRef = useRef();

  // const verifyRecaptcha = async () => {

  //   const token = await recaptchaRef!.current!.executeAsync();
  //   setRecaptchaToken(token);
  // };
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=6Le1xF0oAAAAAJNFQazJy28j8UtY0rdV63rRFl8A`;
    script.async = true;
    document.body.appendChild(script);

    interface Window {
      grecaptcha: any;
    }

    script.onload = () => {
      // Configura reCAPTCHA con tu clave del sitio

      //@ts-ignore
      window.grecaptcha.ready(() => {
        //@ts-ignore
        window.grecaptcha
          .execute("6Le1xF0oAAAAAJNFQazJy28j8UtY0rdV63rRFl8A", {
            action: "submit",
          })
          .then((token: any) => {
            setRecaptchaToken(token);
          });
      });
    };
  }, []);

  useEffect(() => {
    // Agregar un evento de escucha para detectar el final del scroll del contrato

    if (isTermsModalOpen) {
      setTimeout(() => {
        const contractContent = document.getElementById("contract-content");
        if (contractContent) {
          contractContent.addEventListener("scroll", () => {
            if (
              contractContent.scrollHeight - contractContent.scrollTop ===
              contractContent.clientHeight
            ) {
              setHasReadContract(true);
            }
          });
        }
      }, 100); // Retardo de 100 ms para dar tiempo al modal para renderizarse
    }
    return () => {
      // Elimino el evento de escucha cuando el componente se desmonte
      if (isTermsModalOpen) {
        const contractContent = document.getElementById("contract-content");
        if (contractContent) {
          contractContent.removeEventListener("scroll", () => {});
        }
      }
    };
  }, [isTermsModalOpen]);

  const openTermsModal = () => {
    setTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setTermsModalOpen(false);
    setTermsAccepted(false);
  };

  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <TextPresets.TitleH2 className="Step__Content-Title">
            Tell us more about you
          </TextPresets.TitleH2>

          <InputPresets.TextInput
            containerProps={{ className: "Step__Content-InputTwo" }}
            placeholder="Name"
            onChange={(e) => {
              onChange?.(
                {
                  ...formData.detailsStep,
                  name: e.target.value,
                },
                "detailsStep"
              );
            }}
            value={formData.detailsStep.name}
            name="name"
            error={error.name}
          />
          <DateInput
            placeholder={"Birthday"}
            onChange={(update) => {
              onChange(
                {
                  ...formData?.detailsStep,
                  birthday: update.target.value,
                },
                "detailsStep"
              );
            }}
            value={formData.detailsStep.birthday}
            name="birthday"
            error={error.birthday}
          />

          <InputPresets.PhoneInput
            containerProps={{ className: "Step__Content-InputTwo" }}
            placeholder="Phone"
            international={true}
            onChange={(e) => {
              onChange?.(
                {
                  ...formData.detailsStep,

                  phone: e,
                },
                "detailsStep"
              );
            }}
            value={formData.detailsStep.phone}
            name="phone"
            error={error.phone}
          />

          <SelectPresets.SelectInputRounded
            className={cn(
              {
                SelectInputRounded: data.country?.trim() === "",
              },
              "SelectInputBg"
            )}
            placeholder="Country"
            options={[
              {
                label: "Argentina",
                value: "Argentina",
              },
              {
                label: "Bangladesh",
                value: "Bangladesh",
              },
              {
                label: "Brazil",
                value: "Brazil",
              },
              {
                label: "France",
                value: "France",
              },
              {
                label: "Germany",
                value: "Germany",
              },
              {
                label: "India",
                value: "India",
              },
              {
                label: "Indonesia",
                value: "Indonesia",
              },
              {
                label: "Ireland",
                value: "Ireland",
              },
              {
                label: "Italy",
                value: "Italy",
              },
              {
                label: "Malaysia",
                value: "Malaysia",
              },
              {
                label: "Mexico",
                value: "Mexico",
              },
              {
                label: "Netherlands",
                value: "Netherlands",
              },
              {
                label: "New Zealand",
                value: "New Zealand",
              },
              {
                label: "Pakistan",
                value: "Pakistan",
              },
              {
                label: "Philippines",
                value: "Philippines",
              },
              {
                label: "Portugal",
                value: "Portugal",
              },
              {
                label: "Singapore",
                value: "Singapore",
              },
              {
                label: "Spain",
                value: "Spain",
              },
              {
                label: "UK",
                value: "UK",
              },
              {
                label: "USA",
                value: "USA",
              },
              {
                label: "Other",
                value: "Other",
              },
            ]}
            name="country"
            value={formData.detailsStep.country}
            onChangeValue={(value) => {
              onChange?.(
                {
                  ...formData?.detailsStep,
                  country: value,
                },
                "detailsStep"
              );
            }}
            error={error.country}
          />

          {/* 
          <Checkbox label="I accept the Terms & Conditions" /> */}
          {/* Enlace "Terms & Conditions" que abre el modal */}
          <TextPresets.Link openModal={openTermsModal}>
            Terms & Conditions
          </TextPresets.Link>
          {/* Modal para los términos y condiciones */}

          <Modal
            style={{
              position: "absolute",
              top: "10%",
              left: "10%",
              height: "100%",
              maxHeight: "80rem",
              overflow: "scroll",
              display: "block",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "auto",
              marginLeft: "250px",
              alignContent: "center",
            }}
            open={isTermsModalOpen}
            onClose={() => setTermsModalOpen(false)}
          >
            <div
              style={{
                display: "block",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                width: "100%",
                maxWidth: "100rem",
                maxHeight: "100rem",
                borderRadius: "0.5rem",
                padding: "5rem",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextPresets.TitleH1>
                  TERMS AND CONDITIONS FOR CREATORS OF FILTROO
                </TextPresets.TitleH1>
              </div>
              <div
                id="contract-content"
                style={{
                  maxHeight: "40vh", // Establece la altura máxima del contrato
                  overflowY: "auto",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  backgroundColor: "#f5f5f5",
                  color: "#333", // Cambia el color del texto
                  fontSize: "16px", // Tamaño de fuente
                  lineHeight: "1.5", // Espacio entre líneas
                  textAlign: "justify",
                  marginBottom: "1rem", // Agrega margen inferior para separarlo del checkbox
                  border: "1px solid #ddd", // Añade un borde
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Añade sombra
                }}
              >
                {/* Contenido del contrato */}
                <TextPresets.Body>
                  By ticking the box next to the text "Terms and Conditions for
                  Creators," you ("the Creator") agree to be bound by the
                  following agreement with FILTROO, OÜ (hereinafter, the
                  "Company"), a company duly incorporated under the laws of the
                  Republic of Estonia, with registered office at Harju maakond,
                  Tallinn, Lasnamäe linnaosa, Lõõtsa tn 5, 11415, with a tax
                  identification number (VAT) 102381229 and registration number
                  16246756:
                </TextPresets.Body>
                <ol>
                  <li>
                    <strong>LICENSE:</strong>
                    <div>
                      - The Creator grants the Company a non-exclusive,
                      irrevocable, worldwide, royalty-free, and sublicensable
                      license to download, copy, reproduce, distribute,
                      transmit, display, sell, license, or exploit the Content
                      provided in connection with the Company's platform, its
                      digital services, application and/or website.
                    </div>
                  </li>
                  <li>
                    <strong>INTELLECTUAL PROPERTY:</strong>
                    <div>
                      - The Creator retains ownership of any intellectual
                      property rights over the Content uploaded to the Company's
                      platform. Any use of registered trademarks, service marks,
                      copyrights, and other intellectual property rights of
                      TikTok or third parties will be in accordance with
                      applicable intellectual property laws and any other terms
                      and conditions defined by the holders of such rights.
                    </div>
                  </li>
                  <li>
                    <strong>ACKNOWLEDGEMENT:</strong>
                    <div>
                      - The Company agrees to always acknowledge the Creator as
                      the original author of the Content in any use thereof, in
                      accordance with applicable intellectual property laws.
                    </div>
                  </li>
                  <li>
                    <strong>UNAUTHORIZED USE:</strong>
                    <div>
                      - The Company commits not to use the Content for any
                      purpose not expressly permitted by this Agreement or by
                      applicable law.
                    </div>
                  </li>
                  <li>
                    <strong>REPRESENTATIONS AND WARRANTIES:</strong>
                    <div>
                      - The Creator represents and warrants that they have all
                      necessary rights to grant the license described herein to
                      the Company and that the Content does not infringe the
                      rights of third parties, including intellectual property
                      rights.
                    </div>
                  </li>
                  <li>
                    <strong>MODIFICATION OF TERMS:</strong>
                    <div>
                      - The Company reserves the right to modify these Terms and
                      Conditions at any time, and the Creator's continued use of
                      the platform following such modifications will constitute
                      acceptance of the modified Terms and Conditions.
                    </div>
                  </li>
                  <li>
                    <strong>GOVERNING LAW AND JURISDICTION:</strong>
                    <div>
                      - This Agreement will be governed by and interpreted in
                      accordance with the laws of the Republic of Estonia, and
                      any dispute will be resolved in the competent courts of
                      that country. By ticking the box, the Creator accepts and
                      agrees to be bound by the terms and conditions described
                      herein.
                    </div>
                  </li>
                </ol>
                <TextPresets.Body>
                  By ticking the box, the Creator accepts and agrees to be bound
                  by the terms and conditions described herein.
                </TextPresets.Body>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {hasReadContract && (
                  <Checkbox
                    label={`I ${formData.detailsStep.name} accept the Terms & Conditions`}
                    onChange={() => setTermsAccepted(false)}
                  />
                )}
                <ButtonFilledPrimary
                  disabled={termsAccepted}
                  style={{ marginTop: "2rem" }}
                  label="Close"
                  onClick={closeTermsModal}
                />
              </div>
            </div>
          </Modal>

          <TextPresets.TitleH3>
            {error.terms && <span style={{ color: "red" }}>{error.terms}</span>}
          </TextPresets.TitleH3>
        </div>
        <div className="Step__Actionss">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Create account"
            loading={sendingForm}
            onClick={async () => {
              handleSubmit();
            }}
          />

          <div style={{ display: "none" }}>
            <div
              //@ts-ignore
              ref={recaptchaRef}
              className="g-recaptcha"
              data-sitekey="6Le1xF0oAAAAAJNFQazJy28j8UtY0rdV63rRFl8A"
              data-size="invisible"
            ></div>
          </div>
        </div>
      </div>
    </StyledStep>
  );
};
