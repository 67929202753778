import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  let isAuthToken = true;
  if (!isAuthToken) {
    return <Navigate to="/" replace />;
  }

  return children;
};
