import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Campaign } from "../../types/app.types";

// Acciones asíncronas
export const createCampaign = createAsyncThunk(
  "campaigns/createCampaign",
  async (campaign: Campaign) => {
    const { data } = await axios.post("/campaigns", campaign);
    return data;
  }
);

type SliceState = {
  isFetching: boolean;
  didInvalidate: boolean;
  campaign: Campaign | null;
  lastStep: number;
};

// Slice
const campaignsSlice = createSlice({
  name: "campaigns",
  initialState: {
    isFetching: false,
    didInvalidate: false,
    campaign: null,
    lastStep: 0,
  } as SliceState,
  reducers: {
    setCampaign: (state, action: PayloadAction<Campaign>) => {
      state.campaign = { ...state.campaign, ...action.payload };
    },
    resetCampaign: (state) => {
      state.campaign = null;
    },
    setLastStep: (state, action: PayloadAction<number>) => {
      state.lastStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCampaign.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(createCampaign.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(createCampaign.fulfilled, (state) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.campaign = null;
      });
  },
});

export default campaignsSlice;
