import IconButton from "@mui/material/IconButton";
import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 24px;
`;

export const Logo = styled.img`
  width: 100px;
  height: auto;
`;

export const HeaderIcon = styled(IconButton)`
  margin-left: 35px;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
