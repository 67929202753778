import { ButtonPresets } from "../../UI/Button";

import { TextPresets } from "../../UI/Text";
import styled from "styled-components";

import { FormDataType } from "../../../screens/UploadFilterScreen/Views/UploadFilterScreenView";

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export interface PreviewUrlStepProps {
  handleNext: () => void;
  onChange: (value: unknown, field: keyof FormDataType) => void;
  formData: FormDataType;
}

const StyledStep = styled.div`
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 40vh;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-Input {
        width: 100%;
        margin: 0.2rem 0;
        padding: 0 2rem;
      }
    }

    &__Actions {
      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
        margin-top: 1rem;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }
    input[type="file"] {
      display: none;
    }
    .Error_Message {
      color: red;
      font-size: 0.8rem;
      margin: 0.2rem 0;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: center;
    }
    .file-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0.2rem 0;
      padding: 0 2rem;
      .file-upload-icon {
        margin-right: 0.5rem;
      }
    }
    .custom-file-input-label {
      display: inline-block; /* Hace que la etiqueta sea en línea con el contenido */
      margin-left: 10px; /* Espacio entre el botón y la etiqueta */
      max-width: calc(100% - 290px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid #ccc;
    }
    .Recomendation {
      margin: 0.2rem 0;
      margin-top: -10px;
      text-align: center;
      align-items: center;
      display: flex;
      color: rgb(0, 170, 255);
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
`;
const CustomFileUpload = styled.label`
  display: inline-block;
  background-color: hsl(300, 8%, 97%);
  color: black;
  padding: 10px 15px;
  margin: 15px;
  width: 250px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-items: end;
  margin-bottom: 15px;
  text-align: center;

  &:hover {
    background-color: #bad6f3;
  }

  .file-upload-icon {
    margin-right: 8px;
    font-size: 16px;
  }
`;

export const PreviewAndVideoUrlStep = ({
  handleNext,
  onChange,
  formData,
  ...props
}: PreviewUrlStepProps) => {
  const [selectedImageName, setSelectedImageName] = useState("");
  const [selectedVideoName, setSelectedVideoName] = useState("");
  const [sendingForm, setSendingForm] = useState(false);
  const [errors, setErrors] = useState({ image: "", video: "" });
  const [isContinueDisabled, setIsContinueDisabled] = useState(true);

  useEffect(() => {
    if (formData.previewAndVideoUrlStep.image) {
      const imageFile = formData.previewAndVideoUrlStep.image;

      const imageElement = new Image();
      imageElement.src = URL.createObjectURL(imageFile);

      imageElement.onload = () => {
        const width = imageElement.width;
        const height = imageElement.height;

        // Validar dimensiones de la imagen
        if (width < 500 || height < 1000) {
          setErrors({
            ...errors,
            image: "Minimum image size: 300x600 pixels",
          });
          setSendingForm(false);
          return;
        } else {
          setErrors({ ...errors, image: "" });
        }
      };
    }
  }, [formData.previewAndVideoUrlStep.image]);
  const handleSubmit = (e: any) => {
    if (sendingForm) {
      return;
    }
    setSendingForm(true);

    const imageFile = formData.previewAndVideoUrlStep.image;
    const videoFile = formData.previewAndVideoUrlStep.video;

    if (imageFile) {
      const imageElement = new Image();
      imageElement.src = URL.createObjectURL(imageFile);

      imageElement.onload = () => {
        const width = imageElement.width;
        const height = imageElement.height;

        // Validar dimensiones de la imagen
        if (width < 300 || height < 600) {
          setErrors({
            ...errors,
            image: "Minimum image size: 300x600 pixels",
          });
          setSendingForm(false);
          return; // No envía el formulario si las dimensiones no son válidas
        } else {
          setErrors({ ...errors, image: "" });
          continueSubmit(imageFile, videoFile);
        }
      };
    } else {
      continueSubmit(imageFile, videoFile);
    }
  };

  const continueSubmit = (imageFile: File | null, videoFile: File | null) => {
    // Validación de archivos y envío del formulario
    if (!imageFile || !videoFile) {
      setErrors({
        image: !imageFile ? "The image is required" : "",
        video: !videoFile ? "The video is required" : "",
      });

      setSendingForm(false);
      return;
    }
    //validar q no venga vacio

    if (!imageFile || !videoFile) {
      setErrors({
        image: !imageFile ? "The image is required" : "",
        video: !videoFile ? "The video is required" : "",
      });

      setSendingForm(false);
      return;
    }

    // Check file extensions
    const isImageValid = /\.(png|jpg|jpeg|gif)$/.test(imageFile!.name);
    const isVideoValid = /\.(mp4)$/i.test(videoFile!.name);

    if (!isImageValid || !isVideoValid) {
      setErrors({
        image: isImageValid
          ? ""
          : "The image must have a .png, .jpg, .jpeg, or .gif extension",
        video: isVideoValid ? "" : "The video must have a .mp4 extension",
      });

      setSendingForm(false);
    } else if (
      (videoFile && videoFile.size > 20000000) ||
      (imageFile && imageFile.size > 20000000)
    ) {
      setErrors({
        image: "",
        video: "The video must be less than 20MB",
      });

      setSendingForm(false);
    } else {
      handleNext();
    }
  };

  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <TextPresets.TitleH2 className="Step__Content-Title">
            Filter Media
          </TextPresets.TitleH2>

          {/* Input para la imagen */}
          <div className="file-container">
            <CustomFileUpload htmlFor="imageInput">
              <FontAwesomeIcon icon={faUpload} className="file-upload-icon" />
              Select an Icon
            </CustomFileUpload>
            <label className="custom-file-input-label">
              {selectedImageName || "No file selected"}
              {/* Muestra el nombre del archivo */}
            </label>
          </div>
          <TextPresets.BodySmall className="Recomendation">
            Minimum image size: 300x600 pixels
          </TextPresets.BodySmall>

          <input
            type="file"
            id="imageInput"
            accept=".png, .jpg, image/png, image/jpeg"
            onChange={(e) => {
              const selectedFile = e.target.files?.[0];
              if (selectedFile) {
                onChange?.(
                  {
                    ...formData.previewAndVideoUrlStep,
                    image: selectedFile, // Almacena el archivo de imagen seleccionado
                  },
                  "previewAndVideoUrlStep"
                );
                setSelectedImageName(selectedFile.name);
              }
            }}
          />
          {errors.image && <p className="Error_Message">{errors.image}</p>}

          <div className="file-container">
            <CustomFileUpload htmlFor="videoInput">
              <FontAwesomeIcon icon={faUpload} className="file-upload-icon" />
              Select a demo Video
            </CustomFileUpload>

            <label className="custom-file-input-label">
              {selectedVideoName || "No file selected"}{" "}
              {/* Muestra el nombre del archivo */}
            </label>
          </div>

          <input
            type="file"
            id="videoInput"
            accept=".mp4, video/mp4"
            onChange={(e) => {
              const selectedFile = e.target.files?.[0];

              onChange?.(
                {
                  ...formData.previewAndVideoUrlStep,
                  video: selectedFile, // Almacena el archivo de video seleccionado
                },
                "previewAndVideoUrlStep"
              );
              setSelectedVideoName(selectedFile!.name);
            }}
          />

          {errors.video && <p className="Error_Message">{errors.video}</p>}
        </div>

        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Continue"
            onClick={handleSubmit}
            loading={sendingForm}
          />
        </div>
      </div>
      <div className="Step__ChatContainer"></div>
    </StyledStep>
  );
};
