import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { StyledContainer, StyledLink } from "./FiltersDetailScreenView.styles";
import { InsightItem } from "./InsightItem";

import { TextPresets } from "../../../components/UI/Text";
import { Card } from "../../../components/UI/Card";
import { ScreenLoader } from "../../../components/UI/Spinners/presets";
import { ButtonPresets } from "../../../components";

import { Filter } from "../../../types/app.types";

import { KeyboardArrowLeft } from "@mui/icons-material";

interface FiltersScreenDetailViewProps {
  filter: Filter;
  isLoading?: boolean;
}

const FiltersScreenDetailView: FC<FiltersScreenDetailViewProps> = ({
  filter,
  isLoading,
}) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/filters/list");
  };

  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <StyledContainer>
      <div className="GoBack__LeftIcon">
        <ButtonPresets.ButtonText
          style={{ marginTop: "3.8rem" }}
          onClick={handleBack}
        >
          <KeyboardArrowLeft fontSize="large" />
          Go Back
        </ButtonPresets.ButtonText>
      </div>
      <div className="General__Container">
        <Card className="Card__Description">
          <div className="Insights__Container">
            {filter && filter ? (
              <div className="Insights__ImageContainer">
                <img
                  className="Insights__ImageContainer-Image"
                  src={filter.image}
                  alt="filterImage"
                />
              </div>
            ) : filter === false ? null : (
              <div className="Insights__ImageContainer">
                <div className="Insights__ImageContainer-Empty" />
              </div>
            )}
            <div className="Insights__Content">
              <TextPresets.TitleH1>Filter Details</TextPresets.TitleH1>
              <InsightItem title="Filter name" value={filter && filter.name} />

              <InsightItem
                title="Category name"
                value={
                  filter &&
                  filter.category.name &&
                  filter.category.name.charAt(0).toUpperCase() +
                    filter.category.name.slice(1)
                }
              />
              <InsightItem
                className="Insights__Content__Url"
                title="Link of the filter"
                value={
                  filter && (
                    <StyledLink href={filter.instaURL} target="_blank">
                      {filter.instaURL}
                    </StyledLink>
                  )
                }
              />
              <InsightItem
                title="Link of the video"
                value={
                  filter && (
                    <StyledLink href={filter.video} target="_blank">
                      {filter.video}
                    </StyledLink>
                  )
                }
              />
              <InsightItem
                title="TikTok account to be mentioned"
                value={filter && filter.locationName}
              />
            </div>
          </div>
        </Card>
      </div>
    </StyledContainer>
  );
};

export default FiltersScreenDetailView;
