import styled from "styled-components";
import { ButtonPresets } from "../../UI/Button";
import { TextPresets } from "../../UI/Text";
//import ConfirmAccountLogo from "../../../assets/logos/CheckAccountLogo.png";
import ConfirmAccountLogo from "../../../assets/images/assetRevisionCreadores.png";
import errorLogo from "../../../assets/images/error.png";

import { useNavigate } from "react-router-dom";
import { FormDataType } from "../../../screens/UploadFilterScreen/Views/UploadFilterScreenView";

export interface ConfirmUploadFilterStepProps {
  handleNext: () => void;
  formData: FormDataType;
  error: any;
  filterCreated?: boolean;
}

const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 8rem;
      }

      &-Title {
        margin-top: 1rem;
        padding: 0 2rem;
      }
      &-Description {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1.4rem;
      }
    }

    &__Actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
        margin-top: 3rem;
      }

      &-Separator {
        margin: 1rem 0;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }

    .errorLogo {
      width: 100%;
      max-width: 150px;
    }
    .Image {
      width: 100%;
      max-width: 200px;
      margin-bottom: 2rem;
      max-height: 400px;
    }
  }
`;

export const ConfirmUploadFilterStep = ({
  handleNext,
  formData,
  filterCreated,
  error,
  ...props
}: ConfirmUploadFilterStepProps) => {
  const navigate = useNavigate();

  if (filterCreated) {
    return (
      <StyledStep {...props}>
        <div className="Step">
          <div className="Step__Content">
            <div className="Step__Content-Logo">
              <img className="Image" src={ConfirmAccountLogo} alt="Confirm" />
            </div>

            <TextPresets.TitleH2 className="Step__Content-Title">
              Checking your filter in progress!
            </TextPresets.TitleH2>
            <TextPresets.TitleH3 className="Step__Content-Description">
              We're in the lab checking out your filter. Hang tight, we'll get
              back yo you soon with the results!
            </TextPresets.TitleH3>
          </div>
          <div className="Step__Actions">
            <ButtonPresets.ButtonRoundedFilledPrimary
              className="Step__Actions-Button"
              label="Upload new filter"
              onClick={() => navigate("/filters/create")}
            />
          </div>
        </div>
      </StyledStep>
    );
  } else {
    return (
      <StyledStep {...props}>
        <div className="Step">
          <div className="Step__Content">
            <div className="Step__Content-Logo">
              <img className="errorLogo" src={errorLogo} alt="rejected" />
            </div>

            <TextPresets.TitleH2 className="Step__Content-Title">
              Your filter was rejected :(
            </TextPresets.TitleH2>
            <TextPresets.Body className="Step__Content-Description">
              We've reviewed your filter, and unfortunately we've found details
              that need adjustments to ensure it fits our guidelines. Don't
              worry, we're here to help you get it approved if needed.
            </TextPresets.Body>
            <TextPresets.TitleH2 className="Step__Content-Title">
              Reasons
            </TextPresets.TitleH2>
            <li>
              <TextPresets.Body className="Step__Content-Title">
                {error}
              </TextPresets.Body>
            </li>
          </div>
          <div className="Step__Actions">
            <ButtonPresets.ButtonRoundedFilledPrimary
              className="Step__Actions-Button"
              label="Try again"
              onClick={() => navigate("/filters/create")}
            />
            <TextPresets.Body className="Step__Actions-Separator">
              or
            </TextPresets.Body>
            <ButtonPresets.ButtonText
              className="Step__Actions-Button"
              label="Contact us"
              onClick={() => navigate("/home")}
            />
          </div>
        </div>
      </StyledStep>
    );
  }
};
