import { useEffect, useState } from "react";
import Api from "../config/Api";
import { Filter } from "../types/app.types";

export const useGetFiltersByOwner = (
  ownerId: string,
  skip?: string,
  limit?: string,
  category?: string
) => {
  const [data, setdata] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const [error, seterror] = useState(false);

  useEffect(() => {
    getFiltersByCategoryAndOwner(ownerId, skip, limit, category);
  }, []);

  const refreshFilters = async (
    skip: string,
    limit: string,
    category?: string
  ) => {
    await getFiltersByCategoryAndOwner(ownerId, skip, limit, category);
  };

  const getFiltersByCategoryAndOwner = async (
    ownerId: string,
    skip?: string,
    limit?: string,
    category?: string
  ) => {
    setIsLoading(true);
    try {
      const query = new URLSearchParams();
      if (skip) {
        query.append("skip", skip);
      }
      if (limit) {
        query.append("limit", limit);
      }
      if (category) {
        query.append("category", category);
      }

      const { data } = await Api.get<Filter[]>(
        //TODO: cambiar la url
        `https://api.crm.filtroo.co/filters/all/id/${ownerId}?${query}`
      );
      setdata(data);
      setIsLoading(false);
    } catch (error) {
      seterror(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    filters: data.filters,
    limit: data.limit,
    skip: data.skip,
    totalDocuments: data.totalDocuments,
    error,
    getFiltersByCategoryAndOwner,
    refreshFilters,
  };
};
