import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import campaigns from "./slices/campaigns";
import mobile from "./slices/mobile";
import authentication from "./slices/authentication";
import wallet from "./slices/wallet";
import ganalytics from "./slices/ganalytics";
import codesSlice from "./slices/codes";
import filterCategoriesSlice from "./slices/categories";

export const store = configureStore({
  reducer: {
    mobile: mobile.reducer,
    authentication: authentication.reducer,
    campaigns: campaigns.reducer,
    wallet: wallet.reducer,
    ganalytics: ganalytics.reducer,
    codes: codesSlice.reducer,
    filterCategories: filterCategoriesSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Mobile = (state: RootState) => state.mobile;
export const Authentication = (state: RootState) => state.authentication;
export const Campaigns = (state: RootState) => state.campaigns;
export const Wallet = (state: RootState) => state.wallet;
export const Ganalytics = (state: RootState) => state.ganalytics;
export const Codes = (state: RootState) => state.codes;
export const FilterCategories = (state: RootState) => state.filterCategories;
