import { ButtonPresets } from "../../UI/Button";

import { TextPresets } from "../../UI/Text";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { FormDataType } from "../../../screens/UploadFilterScreen/Views/UploadFilterScreenView";
import { SelectPresets } from "../../UI/Select";
import cn from "classnames";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getAllfilterCategories } from "../../../redux/slices/categories";
import { ScreenLoader } from "../../UI/Spinners/presets";
import Joi from "joi";

export interface CategoryStepProps {
  handleNext: () => void;
  onChange: (value: unknown, field: keyof FormDataType) => void;
  formData: FormDataType;
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
    handleSubmit: any
  ) => void;
}

const formDataSchema = Joi.string().required();

const StyledStep = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-Input {
        width: 100%;
        margin: 0.2rem 0;
        padding: 0 2rem;
      }
    }

    &__Actions {
      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
        margin-top: 1rem;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }

    input {
      padding: 0 2rem;
      background: #d2cecedf;
      text-align: center;
    }
    .SelectInputRounded {
      color: #6e6b6b;
    }
    .SelectInputBg {
      width: 100%;
      margin: 0.2rem 0;
      background-color: #f5f5f5;
      margin-bottom: 1rem;
    }
    .Error_Message {
      color: red;
      font-size: 0.8rem;
      margin: 0.2rem 0;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 2.4rem;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }
`;

export const CategoryStep = ({
  handleNext,
  onChange,
  formData,
  handleKeyDown,
  ...props
}: CategoryStepProps) => {
  const dispatch = useAppDispatch();
  const { categories, isFetching } = useAppSelector(
    (state) => state.filterCategories
  );
  const getCategories = async () => {
    await dispatch(getAllfilterCategories()).unwrap();
  };

  useEffect(() => {
    getCategories();
  }, []);

  const [categoryError, setCategoryError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (field: any, value: any) => {
    onChange(
      {
        ...formData?.categoryStep,
        [field]: value,
      },
      "categoryStep"
    );
  };

  const handleSubmit = () => {
    const { error } = formDataSchema.validate(formData.categoryStep.category);

    if (error) {
      setCategoryError(true);
      setErrorMessage("You must complete the category field.");
    } else {
      setCategoryError(false);
      //console.log("categoryStep", formData);

      handleNext();
    }
  };

  if (isFetching) {
    return <ScreenLoader />;
  }
  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <TextPresets.TitleH2 className="Step__Content-Title">
            Filter Category
          </TextPresets.TitleH2>

          <SelectPresets.SelectInputRounded
            className={cn(
              {
                SelectInputRounded:
                  formData.categoryStep.category.trim() === "",
              },
              "SelectInputBg"
            )}
            placeholder="Filter category"
            options={categories.map((category) => ({
              label: category.name,
              value: category._id,
            }))}
            name="category"
            value={formData.categoryStep.category}
            onChangeValue={(value) => handleChange("category", value)}
            error={categoryError}
            onKeyDown={(e: any) => handleKeyDown(e, handleSubmit)}
          />

          {categoryError && <p className="Error_Message">{errorMessage}</p>}
        </div>
        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Continue"
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div className="Step__ChatContainer"></div>
    </StyledStep>
  );
};
