import { Card } from "@mui/material";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Autoplay, Navigation } from "swiper/modules";
import "./styles.css";
import { SwiperSlide, Swiper } from "swiper/react";
const HorizontalCarousel = ({ images, navigate }: any) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      slidesPerView={1}
      spaceBetween={10}
      breakpoints={{
        "@0.00": {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        "@0.75": {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        "@1.00": {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        "@1.50": {
          slidesPerView: 5,
          spaceBetween: 50,
        },
      }}
    >
      {images &&
        images.map((filter: any) => (
          <SwiperSlide key={filter._id}>
            <Card
              className="card"
              onClick={() => navigate(`/filters/${filter._id}`)}
            >
              <img src={filter.image} alt="filter" />
              <div>
                <h3 className="card__title">{filter.name}</h3>
              </div>
            </Card>
            <a
              href={filter.instaURL}
              className="card__share-button"
              target="_blank"
              rel="noreferrer"
            >
              Share
            </a>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default HorizontalCarousel;
