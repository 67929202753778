import {
  ButtonPresets,
  ProgressStepper,
  TextPresets,
} from "../../../components";
import { useEffect, useState } from "react";
import { useForm } from "../../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { Styles } from "./UploadFilterScreenView.styles";
import { NameStep } from "../../../components/UploadFilters/Steps/NameStep";
import { CategoryStep } from "../../../components/UploadFilters/Steps/CategoryStep";
import { FilterUrlStep } from "../../../components/UploadFilters/Steps/FilterUrlStep";
import { PreviewAndVideoUrlStep } from "../../../components/UploadFilters/Steps/PreviewAndVideoUrlStep";
import Api from "../../../config/Api";
import { ConfirmUploadFilterStep } from "../../../components/UploadFilters/Steps/ConfirmUploadFilterStep";
import { NickNameStep } from "../../../components/UploadFilters/Steps/NickNameStep";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface UploadFilterScreenViewProps {
  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;
}

export interface FormDataType {
  nameStep: { name: string };
  categoryStep: { category: string };
  filterUrlStep: { filterUrl: string };
  nickNameStep: { nickName: string | null };
  previewAndVideoUrlStep: { image: File | null; video: File | null };

  // tiktokAccountStep: { tiktokAccount: string };
}

const renderUploadFilterStep = (
  activeStep: number,
  handleNext: () => void,
  onChange: (value: unknown, field: keyof FormDataType) => void,
  formData: FormDataType,
  handleKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
    handleSubmit: any
  ) => void,
  filterCreated?: boolean,
  errorAxios?: any
) => {
  switch (activeStep) {
    case 0:
      return (
        <NameStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
          handleKeyDown={handleKeyDown}
        />
      );
    case 1:
      return (
        <CategoryStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
          handleKeyDown={handleKeyDown}
        />
      );
    case 2:
      return (
        <FilterUrlStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
          handleKeyDown={handleKeyDown}
        />
      );

    case 3:
      return (
        <NickNameStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
          handleKeyDown={handleKeyDown}
        />
      );
    case 4:
      return (
        <PreviewAndVideoUrlStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
        />
      );

    // case 5:
    //   return (
    //     <TiktokAccountStep
    //       handleNext={handleNext}
    //       onChange={onChange}
    //       formData={formData}
    //     />
    //   );

    case 5:
      return (
        <ConfirmUploadFilterStep
          handleNext={handleNext}
          filterCreated={filterCreated}
          formData={formData}
          error={errorAxios}
        />
      );

    default:
      return null;
  }
};

const UploadFilterScreenView = ({
  setSubmitting,
  submitting,
}: UploadFilterScreenViewProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [filterCreated, setFilterCreated] = useState(false);
  const [errorAxios, setErrorAxios] = useState({});
  // //busco el id del usuario en el redux
  // const userId = useSelector(
  //   (state: RootState) => state.authentication.user?._id
  // );

  const navigate = useNavigate();

  //traer usuario de redux
  const user = useSelector((state: RootState) => state.authentication.user);

  const initialFormData: FormDataType = {
    nameStep: { name: "" },
    categoryStep: { category: "" },
    filterUrlStep: { filterUrl: "" },
    nickNameStep: { nickName: user?.nickCreator || null },
    previewAndVideoUrlStep: { image: null, video: null },
    // tiktokAccountStep: { tiktokAccount: "" },
  };

  const { form: formData, onChange } = useForm<FormDataType>(initialFormData);

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/filters/create");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = async () => {
    if (activeStep === 4) {
      try {
        //https://api.crm.filtroo.co
        await Api.post("https://api.crm.filtroo.co/filters/add-filter", {
          name: formData.nameStep.name,
          category: formData.categoryStep.category,
          filterUrl: formData.filterUrlStep.filterUrl,
          image: formData.previewAndVideoUrlStep.image,
          video: formData.previewAndVideoUrlStep.video,
          location: "@filtroo.co",
          nickName: formData.nickNameStep.nickName,
        });
        setFilterCreated(true);
      } catch (error: any) {
        console.error("Error al crear filtro:", error);
        setErrorAxios(
          error?.response?.data?.message ?? error?.response?.data.errors[0].msg
        );
        setFilterCreated(false);
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    if (submitting) {
      // Restablecer el estado de 'submitting' a false después de enviar los datos
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, submitting]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    handleSubmit: any
  ) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <Styles>
      <div className="Stepper__Content">
        {activeStep < 5 && (
          <>
            <ButtonPresets.ButtonText onClick={handleBack}>
              <KeyboardArrowLeft
                className="Stepper__LeftIcon"
                fontSize="large"
              />
              Go Back
            </ButtonPresets.ButtonText>

            <ProgressStepper
              steps={6}
              activeStep={activeStep}
              className="Stepper"
              nextButton={null}
              backButton={null}
            />
          </>
        )}

        {activeStep < 5 && (
          <TextPresets.BodySmall>{activeStep + 1}/6</TextPresets.BodySmall>
        )}
      </div>
      {renderUploadFilterStep(
        activeStep,
        handleNext,
        onChange,
        formData,
        handleKeyDown,
        filterCreated,
        errorAxios
      )}
    </Styles>
  );
};

export default UploadFilterScreenView;
