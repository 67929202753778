import { ReactNode } from "react";
import styled from "styled-components";

const LinkStyle = styled.a`
  color: #0070f3;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.5;
  &:hover {
    text-decoration: underline;
  }
`;

interface LinkProps {
  children: ReactNode;
  href?: string;
  openModal?: () => any;
}

export const Link = ({ children, href, openModal }: LinkProps): JSX.Element => {
  return (
    <LinkStyle href={href} onClick={openModal}>
      {children}
    </LinkStyle>
  );
};
