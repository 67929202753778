import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(127deg, #e2eef6 0%, #f0f7fa 20.21%, #f6f2e9 100%);

  .Auth__Logo {
    width: 30rem;
    margin: 3rem 5rem;
  }

`;
