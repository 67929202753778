import { FC } from "react";

import styled from "styled-components";
import { ButtonPresets, TextPresets } from "../../components";
import { useNavigate } from "react-router-dom";
import RocketImg from "../../assets/images/cohete.png";
const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      // imagen mas chica
      &-Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 8rem;
      }

      &-Title {
        margin: 1;
        padding: 0 2rem;
      }
    }

    &__Actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
      }

      &-Separator {
        margin: 1rem 0;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }
    img {
      width: 100%;
      height: 100%;
      max-width: 100rem;
      max-height: 100rem;
    }
  }
`;
const UploadFilterScreen: FC = () => {
  const navigate = useNavigate();

  return (
    <StyledStep>
      <div className="Step">
        <div className="Step__Content">
          <div className="Step__Content-Logo">
            <img src={RocketImg} alt="Confirm" />
          </div>

          <TextPresets.TitleH2 className="Step__Content-Title">
            Upload new filter
          </TextPresets.TitleH2>
          <TextPresets.TitleH3 className="Step__Content-Title">
            You will earn 10 points (1$) for every filter you upload on Filtroo.
          </TextPresets.TitleH3>
        </div>
        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Upload"
            onClick={() => navigate("/filters/upload")}
          />
        </div>
      </div>
    </StyledStep>
  );
};

export default UploadFilterScreen;
