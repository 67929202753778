import MobileStepper, { MobileStepperProps } from '@mui/material/MobileStepper';

export interface ProgressStepperProps extends MobileStepperProps {
  activeStep: number;
}

export const ProgressStepper = ({activeStep,  ...props}: ProgressStepperProps) => {
  return (
    <MobileStepper
      {...props}
      variant="progress"
      position="static"
      activeStep={activeStep}
      sx={{ flexGrow: 1 }}
    />
  );
}
