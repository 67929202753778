import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Api from "../../config/Api";

export const getAllfilterCategories = createAsyncThunk(
  "filter/categories",
  async () => {
    const { data } = await Api.get("https://api.filtroo.co/categoryfilter/all");
    return data;
  }
);

interface ICategories {
  _id: string;
  name: string;
  image: string;
}

interface InitialState {
  isFetching: boolean;
  categories: ICategories[];
}
const initialState: InitialState = {
  isFetching: false,
  categories: [],
};

export const filterCategoriesSlice = createSlice({
  name: "filterCategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllfilterCategories.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getAllfilterCategories.fulfilled, (state, action) => {
        state.isFetching = false;
        state.categories = action.payload;
      })
      .addCase(getAllfilterCategories.rejected, (state, action) => {
        state.isFetching = false;
        state.categories = [];
      });
  },
});
export default filterCategoriesSlice;

export const selectWallet = (state: RootState) => state;
