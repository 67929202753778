import "react-datepicker/dist/react-datepicker.css";
import "swiper/css";
import "swiper/css/navigation";

import { useAppDispatch, useAppSelector } from "./redux/store";
import { useEffect, useState } from "react";

import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "./GlobalStyles";
import { Routes } from "./routes";
import { SelectGlobalStyles } from "./components/UI/Select";
import axios from "axios";
import { config } from "./config";
import { loadUserSession } from "./redux/slices/authentication";
import { syncWallet } from "./redux/slices/wallet";
import { getTotalAnalyticsForCampaign } from "./redux/slices/ganalytics";


const App = () => {
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  const {token, user} = useAppSelector((state) => state.authentication);
  const dispatch = useAppDispatch()
  
  useEffect(() => {
    dispatch(loadUserSession())
    // Configuro la url base de axios antes de cargar la web
    axios.defaults.baseURL = `${config.API_URL}`;
    axios.defaults.withCredentials = false;
    setIsLoadingApp(false);
  }, []);

  useEffect(() => {
    if(token){
      dispatch(syncWallet())
      dispatch(getTotalAnalyticsForCampaign(user?._id))
    }
  }, [token])

  if (isLoadingApp) return null;

  return (
    <>
      <GlobalStyle />
      <SelectGlobalStyles />
      <BrowserRouter>
        <Routes userToken={token} />
      </BrowserRouter>
    </>
  );
};

export default App;
