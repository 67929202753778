import { FC } from "react";
import FiltersScreenView from "./Views/FiltersScreenView";

import { useAppSelector } from "../../redux/store";
import { useGetFiltersByOwner } from "../../hooks/useGetFiltersByOwner";
import { useNavigate } from "react-router-dom";

const FiltersScreen: FC = () => {
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.authentication);
  const { filters, isLoading } = useGetFiltersByOwner(user!._id);

  return (
    <FiltersScreenView
      filters={filters}
      isLoading={isLoading}
      navigate={navigate}
    />
  );
};

export default FiltersScreen;
