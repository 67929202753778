import {
  ConfirmAccountStep,
  DetailsStep,
  EmailPasswordStep,
} from "../../../components/Register/Steps";
import { useEffect, useState } from "react";

import { ButtonPresets } from "../../../components/UI/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { ProgressStepper } from "../../../components/UI/ProgressStepper";
import { Styles } from "./RegisterScreenView.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "../../../hooks/useForm";
import { Modal } from "@mui/material";
import { TextPresets } from "../../../components";

interface RegisterScreenViewProps {
  submitting: boolean;
  userCreated?: boolean;
  handleCreateUser: (formData: FormDataType) => Promise<boolean>;
  setSubmitting: (submitting: boolean) => void;
}

export interface FormDataType {
  emailPasswordStep: { email: string; password: string | null }; // Añade más campos aquí según sea necesario
  detailsStep: {
    name: string;
    phone: string;
    birthday: string;
    country: string;
  };
  providerType: string;
  profileImage?: string;
}

const renderRegisterStep = (
  activeStep: number,
  handleNext: () => void,
  onChange: (value: unknown, field: keyof FormDataType) => void,
  formData: FormDataType,
  userCreated?: boolean
) => {
  switch (activeStep) {
    case 0:
      return (
        <EmailPasswordStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
        />
      );
    case 1:
      return (
        <DetailsStep
          handleNext={handleNext}
          onChange={onChange}
          formData={formData}
        />
      );

    case 2:
      if (userCreated) {
        return <ConfirmAccountStep handleNext={handleNext} />;
      } else {
        return (
          <Modal
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
            onClose={() => {
              window.location.reload();
            }}
            open={true}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "100px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
                fontSize: "20px",
              }}
            >
              User already registered.
            </div>
          </Modal>
        );
      }
    default:
      return null;
  }
};

const RegisterScreenView = ({
  userCreated,
  handleCreateUser,
  submitting,
  setSubmitting,
}: RegisterScreenViewProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const infoGoogleUser = location.state;

  const initialFormData: FormDataType = {
    emailPasswordStep: {
      email: infoGoogleUser?.email || "",
      password: "",
    },
    detailsStep: {
      name: infoGoogleUser?.name || "",
      phone: "",
      birthday: "",
      country: "",
    },
    providerType: "email",
  };
  //const [formData, setFormData] = useState<FormDataType>(initialFormData);
  const {
    form: formData,
    onChange,
    setFormValue,
  } = useForm<FormDataType>(initialFormData);

  useEffect(() => {
    if (infoGoogleUser) {
      setFormValue({
        emailPasswordStep: {
          email: infoGoogleUser?.infoGoogleUser.email,
          password: null,
        },
        detailsStep: {
          name: infoGoogleUser?.infoGoogleUser.name,
          phone: "",
          birthday: "",
          country: "",
        },
        providerType: "GOOGLE",
        profileImage: infoGoogleUser?.infoGoogleUser.picture,
      });

      setActiveStep(1);
    }
  }, []);
  // const updateFormData = async (step: keyof FormDataType, data: any) => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [step]: data,
  //   }));

  //   console.log("activeStep", activeStep);
  //   if (activeStep === 1) {
  //     // Establecer el estado de 'submitting' a true cuando estés listo para enviar los datos
  //     setSubmitting(true);
  //   }
  // };s

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/login");
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const registerUser = (formData:FormDataType) => {
  //   return new Promise<boolean>(async (resolve, reject) => {
  //     try{
  //       const result =
  //       resolve(result)
  //     }catch(error){
  //       reject(error)
  //     }
  //   })
  // }

  const handleNext = async () => {
    if (activeStep === 1) {
      //console.log("FORMDATA", formData);
      await handleCreateUser(formData);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleExit = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (submitting) {
      // Restablecer el estado de 'submitting' a false después de enviar los datos
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, submitting]);

  return (
    <Styles>
      <div className="Stepper__Content">
        {activeStep < 2 && (
          <>
            <ButtonPresets.ButtonText onClick={handleBack}>
              <KeyboardArrowLeft
                className="Stepper__LeftIcon"
                fontSize="large"
              />
              Go Back
            </ButtonPresets.ButtonText>

            <ProgressStepper
              steps={2}
              activeStep={activeStep}
              className="Stepper"
              nextButton={null}
              backButton={null}
            />
          </>
        )}

        {activeStep < 2 && (
          <TextPresets.BodySmall>{activeStep + 1}/2</TextPresets.BodySmall>
        )}
      </div>
      {renderRegisterStep(
        activeStep,
        handleNext,
        onChange,
        formData,
        userCreated
      )}
    </Styles>
  );
};

export default RegisterScreenView;
