import React, { ReactNode, useState } from "react";
import { Link, Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import {
  faArrowRightFromBracket,
  faXmark,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

import FiltrooLogo from "../../../assets/logos/blueFiltrooBlack.png";
import coins from "../../../assets/images/IsoCirculoAzul.png";
import homeIcon from "../../../assets/icons/home.svg";
import campaignsIcon from "../../../assets/icons/campaigns.svg";
import filterIcon from "../../../assets/icons/filters.svg";
import walletIcon from "../../../assets/icons/wallet.svg";

import Typography from "@mui/material/Typography";
import { HeaderIcon, Logo, LogoContainer } from "./LayoutFiltrooView.styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "../../../redux/store";
import { styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface LayoutProps {
  children?: ReactNode;
  handleSignout?: () => void;
}

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
    fontSize: 18,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: 18,
  },
}));

function isImageURLValid(url: any) {
  const img = new Image();
  img.src = url;
  return img.complete && img.naturalWidth > 0 && img.naturalHeight > 0;
}

const LayoutFiltrooView = ({ handleSignout, children }: LayoutProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useAppSelector((state) => state.authentication);
  const [userImageLoaded, setUserImageLoaded] = useState(false);
  const defaultImageURL =
    "https://icon-library.com/images/white-profile-icon/white-profile-icon-6.jpg";

  const avatarURL = user?.image;

  const imageUrl = isImageURLValid(avatarURL) ? avatarURL : defaultImageURL;
  const handleAvatarClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleSignout?.();
    handleMenuClose();
  };

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#ffffff",
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <LogoContainer>
            <Logo src={FiltrooLogo} />
          </LogoContainer>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {/* <HeaderIcon aria-label="add user">
              <img src={addUserIcon} alt="" />
            </HeaderIcon> */}

            <BootstrapTooltip title="Total Points">
              <HeaderIcon>
                <LogoContainer>
                  <Logo
                    style={{
                      width: 40,
                      height: 40,
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                    src={coins}
                  />
                  <h3
                    style={{
                      marginLeft: "0.5rem",
                    }}
                  >
                    {user?.userCoins}
                  </h3>
                </LogoContainer>
              </HeaderIcon>
            </BootstrapTooltip>

            <HeaderIcon
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleAvatarClick}
            >
              <img
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  objectFit: "cover",
                  objectPosition: "center",
                  backgroundColor: "#00AAFF",
                  display: userImageLoaded ? "block" : "none",
                }}
                src={imageUrl}
                onLoad={() => setUserImageLoaded(true)}
                alt=""
              />
            </HeaderIcon>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "1.6rem",
                  width: "300px",
                  cursor: "default",
                }}
                disabled={true}
              >
                <FontAwesomeIcon
                  style={{
                    marginRight: "1rem",
                  }}
                  icon={faAt}
                />
                {user?.email}
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "1.6rem",
                  width: "200px",
                }}
                onClick={handleLogout}
              >
                <FontAwesomeIcon
                  style={{
                    marginRight: "1rem",
                  }}
                  icon={faArrowRightFromBracket}
                />
                Log out
              </MenuItem>
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  fontWeight: 300,
                  fontSize: "1.6rem",
                  width: "200px",
                }}
                onClick={handleMenuClose}
              >
                <FontAwesomeIcon
                  style={{
                    marginRight: "1rem",
                  }}
                  icon={faXmark}
                />
                Close
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        PaperProps={{
          sx: {
            backgroundColor: "#00AAFF",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <List>
          <ListItem
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#0092E8",
              },
            }}
            component={Link}
            to="/home"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={homeIcon} alt="" style={{ width: 25 }} />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                }}
              >
                Home
              </Typography>
            </Box>
          </ListItem>
          <ListItem
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#0092E8",
              },
            }}
            component={Link}
            to="/filters/create"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={campaignsIcon} alt="" style={{ width: 25 }} />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                }}
              >
                Create
              </Typography>
            </Box>
          </ListItem>
          <ListItem
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#0092E8",
              },
            }}
            component={Link}
            to="/filters/list"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={filterIcon} alt="" style={{ width: 25 }} />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                }}
              >
                Filters
              </Typography>
            </Box>
          </ListItem>
          <ListItem
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#0092E8",
              },
            }}
            component={Link}
            to="/filters/wallet"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={walletIcon} alt="" style={{ width: 25 }} />
              </ListItemIcon>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: "Poppins, sans-serif",
                  color: "white",
                }}
              >
                Wallet
              </Typography>
            </Box>
          </ListItem>
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, padding: "6rem 3rem 3rem 12rem" }}
      >
        {/* Contenido principal */}
        <Outlet />
        {children}
      </Box>
    </Box>
  );
};

export default LayoutFiltrooView;
