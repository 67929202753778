import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  max-width: 37rem;
  margin: 0 auto;

  .MuiSelect-select.MuiSelect-select {
    padding: 0;
    padding-left: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: red;
  }

  .Login__Form {
    &-Input {
      width: 100%;
      margin: 0.2rem 0;
    }

    &-RememberAction {
      display: flex;
      margin: 2rem 0;
      width: 100%;
      justify-content: space-between;

      &-Checkbox {
        & > label > span {
          font-size: 1.2rem !important;
        }
      }

      &-Button {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
      }
    }

    &-Actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 1rem 0;
      margin-top: 6rem;

      &-Login {
        width: 100%;
      }

      &-Google {
        width: 100%;
        background-color: white;
        border: 1px solid white;
      }

      &-TextContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
      }

      &-Text {
        margin: 0 1rem;
      }

      &-SignUp {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
      }
    }
  }

  .Login__Form-Actions-TextContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }

  .custom-google-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    height: 3rem;
    border-radius: 0.5rem;
    background-color: #00aaff;
    color: white;
    border: none;
    cursor: pointer;
    margin: 1rem 0;
    font-size: 1.2rem;
  }

  .custom-google-button:hover {
    background-color: #357ae8;
  }

  .google-icon {
    width: 2rem;
    margin-right: 1rem;
  }
  .google-button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    width: 100%;
  }
`;
