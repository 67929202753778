import { useState } from "react";
import styled from "styled-components";
import OtpInput from "react18-input-otp";
import { ButtonPresets } from "../../UI/Button";
import { TextPresets } from "../../UI/Text";
import ChatbotIcon from "../../../assets/icons/chatbot.png";

export interface ValidationOTPStepProps {
  handleNext: () => void;
}

const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-OTP {
        &-Container {
          justify-content: center;
          align-items: center;
        }

        &-Input {
          margin: 0 0.5rem;
          border: none;
          border-radius: 1rem;
          width: 11rem !important;
          height: 11rem !important;
          font-size: 4rem;
        }

        &-Separator {
          width: 2.5rem;
        }
      }

      &-Resend {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__Actions {
      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;
      
      &-Icon {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const ValidationOTPStep = ({
  handleNext,
  ...props
}: ValidationOTPStepProps) => {
  const [otp, setOtp] = useState("");
  const handleChange = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };
  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <TextPresets.TitleH2>Type the code we sent you</TextPresets.TitleH2>

          <div className="Step__Content-OTP">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span className="Step__Content-OTP-Separator"></span>}
              separateAfter={3}
              onSubmit={() => console.log(otp)}
              isInputNum
              containerStyle="Step__Content-OTP-Container"
              inputStyle="Step__Content-OTP-Input"
            />
          </div>

          <div className="Step__Content-Resend">
            <TextPresets.TitleH3 className="Step__Content-Resend-Title">
              Haven't received it yet?
            </TextPresets.TitleH3>
            <ButtonPresets.ButtonText
              className="Step__Content-Resend-Button"
              label="Resend Code"
            />
          </div>
        </div>
        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Verify"
            onClick={handleNext}
          />
        </div>
      </div>
      <div className="Step__ChatContainer">
        <ButtonPresets.ButtonTextPrimary className="Step__ChatContainer-Button">
        <img src={ChatbotIcon} alt="chatbot" className="Step__ChatContainer-Icon"/>Message chatbot
        </ButtonPresets.ButtonTextPrimary>
      </div>
    </StyledStep>
  );
};
