import styled from "styled-components";
import "react-phone-number-input/style.css";
import { TextInput } from "./TextInput";
import PhoneInputBase, {
  Props,
  DefaultInputComponentProps,
} from "react-phone-number-input";
import { GrayScale } from "../../../../assets/theme/Colors";

const StyledPhoneInput = styled(PhoneInputBase)`
  background-color: ${GrayScale.FullLight};
  border-radius: 3rem;
  padding: 0rem 2rem;

  .PhoneInputCountry {
    & > select {
      border-radius: 3rem;
    }

    & > select > option {
      font-size: 1.2rem;
      font-family: Poppins, system-ui, -apple-system, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      min-height: 0.5rem;
    }
  }
`;

export interface PhoneInputProps extends Props<DefaultInputComponentProps> {}

export const PhoneInput = ({ value, onChange, ...props }: PhoneInputProps) => {
  return (
    <StyledPhoneInput
      {...props}
      defaultCountry="ES"
      countryCallingCodeEditable={false}
      value={value}
      onChange={onChange}
      inputComponent={TextInput}
    />
  );
};
