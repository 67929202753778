import { FC } from "react";
import WalletScreenView from "./Views/WalletScreenView";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

import bestHostels from "../../assets/images/carousel/Besthotels.png";
import dell from "../../assets/images/carousel/Dell.png";
import Europcar from "../../assets/images/carousel/Europcar.png";
import fiverr from "../../assets/images/carousel/Fiverr.png";
import fnac from "../../assets/images/carousel/FNAC.png";
import hotels from "../../assets/images/carousel/Hotels.png";
import ikea from "../../assets/images/carousel/Ikea.png";
import kenzo from "../../assets/images/carousel/Kenzo.png";
import loccitane from "../../assets/images/carousel/Loccitane.png";
import nesspreso from "../../assets/images/carousel/Nesspreso.png";
import nike from "../../assets/images/carousel/Nike.png";
import sixt from "../../assets/images/carousel/Sixt.png";
import tiendaAnimal from "../../assets/images/carousel/Tiendanimal.png";
import vilebrequin from "../../assets/images/carousel/Vilebrequin.png";
import zalando from "../../assets/images/carousel/Zalando.png";
import { useGetFiltersByOwner } from "../../hooks/useGetFiltersByOwner";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import isoFiltrooIcon from "../../assets/images/IsoCirculoAzul.png";

const WalletScreen: FC = () => {
  const images = [
    bestHostels,
    dell,
    Europcar,
    fiverr,
    fnac,
    hotels,
    ikea,
    kenzo,
    loccitane,
    nesspreso,
    nike,
    sixt,
    tiendaAnimal,
    vilebrequin,
    zalando,
  ];
  const { user } = useAppSelector((state) => state.authentication);

  const { filters, isLoading } = useGetFiltersByOwner(user!._id);

  const rows =
    filters &&
    filters.map((filter: any) => {
      return {
        id: filter._id,
        image: filter.image,
        name: filter.name,
        pointsEarned: 10,
      };
    });

  const columns: GridColDef[] = [
    {
      field: "image",
      headerName: "Image",
      flex: 0.1,
      minWidth: 80,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value == null) {
          return "";
        }

        return (
          <img src={params.value} style={{ width: "4.5rem" }} alt="filter" />
        );
      },
    },

    {
      field: "name",
      headerName: " Name",
      minWidth: 170,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value == null) {
          return "";
        }
        return params.value;
      },
    },

    {
      field: "pointsEarned",
      headerName: " Points Earned",
      minWidth: 170,
      flex: 0.2,
      renderCell: (params: GridRenderCellParams<string>) => {
        if (params.value == null) {
          return "";
        }

        return (
          <div>
            <img
              src={isoFiltrooIcon}
              style={{ width: "1.3rem", marginRight: "1rem" }}
              alt="icon"
            />
            {params.value}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <WalletScreenView
        images={images}
        rows={rows}
        columns={columns}
        isLoading={isLoading}
      />
    </>
  );
};

export default WalletScreen;
