import { FC } from "react";
import { TextPresets } from "../../../components";
import { Styles } from "./WalletScreenView.styles";
import { Card } from "../../../components/UI/Card";

import qr from "../../../assets/images/qr.png";
import stores from "../../../assets/images/appstoreGooglePlay.png";
import VerticalCarousel from "../../../components/Carousel/VerticalCarousel";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DashboardHeader } from "../../../components/Dashboard/Header/DashboardHeader";
import isoFiltrooIcon from "../../../assets/images/IsoCirculoAzul.png";
import { ScreenLoader } from "../../../components/UI/Spinners/presets";
interface WalletScreenProps {
  images: any;
  rows: any[];
  columns: GridColDef[];
  isLoading: boolean;
  ganalytics?: any;
}

const WalletScreenView: FC<WalletScreenProps> = ({
  images,
  rows,
  columns,
  isLoading,
  ganalytics,
}: WalletScreenProps) => {
  if (isLoading) {
    return <ScreenLoader />;
  }

  return (
    <Styles>
      <DashboardHeader sectionName="My benefits" currentRoute="Insights" />

      <div className="Container">
        <div className="Benefits">
          <TextPresets.TitleH1 className="Title">
            Total points earned:
          </TextPresets.TitleH1>
          <div className="Total-Points">
            <img
              style={{ width: 45, height: 45 }}
              className="IsoFiltroo"
              src={isoFiltrooIcon}
              alt={`IsoFiltroo`}
            />
            <TextPresets.TitleH1 className="Points">
              {rows &&
                rows.map((row) => row.pointsEarned).reduce((a, b) => a + b, 0)}
            </TextPresets.TitleH1>
          </div>
          {rows && (
            <Card className="DB_Datagrid">
              <TextPresets.TitleH1 className="DB_Datagrid-Title">
                Filters benefits
              </TextPresets.TitleH1>
              <div className="DB_Datagrid-Container">
                <DataGrid
                  className="DB_Datagrid-Table"
                  rows={rows && rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  disableSelectionOnClick
                />
              </div>
            </Card>
          )}
        </div>

        <div className="Card_Container">
          <div className="First_Container">
            <div className="First_Card_Container">
              <div className="Content_Title">
                <TextPresets.TitleH1>Download Filtroo</TextPresets.TitleH1>
              </div>
              <div>
                <TextPresets.TitleH2>
                  Turn your TikTok filters into profit
                </TextPresets.TitleH2>
              </div>
              <div>
                <img className="Card_Qr_Img" src={qr} alt={`QR`} />
              </div>
              <div>
                <img
                  className="Card_Stores_Img"
                  src={stores}
                  alt={`Google Play & App Store`}
                />
              </div>
            </div>
          </div>
          <VerticalCarousel images={images} />
        </div>
      </div>
    </Styles>
  );
};

export default WalletScreenView;
