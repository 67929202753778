import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api from "../../config/Api";

interface CodeGeneratorResponse {
  status: string;
}

export const generateCodeAction = createAsyncThunk(
  "codes/codegenerator",
  async (code: string) => {
    const { data } = await Api.post<CodeGeneratorResponse>(
      `/codes/codegenerator/${code}`
    );
    return data;
  }
);

interface CodeState {
  isFetching: boolean;
  name: string;
  response: any;
}

const initialState: CodeState = {
  isFetching: false,
  name: "",
  response: null,
};

export const codesSlice = createSlice({
  name: "codes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateCodeAction.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(generateCodeAction.fulfilled, (state, action) => {
        state.isFetching = false;
        state.response = action.payload;
      })
      .addCase(generateCodeAction.rejected, (state, action) => {
        state.isFetching = false;
        state.name = "Error";
        state.response = action.error;
      });
  },
});

export default codesSlice;
