import styled from "styled-components";
import { MainColors } from "../../../assets/theme/Colors";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: center;
  //min-width: 400px;
  //padding: 0 40rem;

  .Stepper {
    background-color: transparent;
    display: flex;
    justify-content: space-between; /* Centra los componentes horizontalmente */
    align-items: center; /* Centra los componentes verticalmente */
    max-height: 6rem;
    max-width: 100%;

    &__Content {
      display: flex;
      align-items: center;
      margin-right: 11rem;
    }

    &__LeftIcon {
      margin-right: 1rem;
    }

    & .MuiMobileStepper-progress {
      height: 1rem;
      border-radius: 0.9rem;
      width: 100%; /* Ajusta el ancho de la barra de progreso según tus preferencias */
      background-color: ${MainColors.primaryLight};
      display: flex; /* Permite que los elementos internos sean flexibles */
    }

    & .MuiLinearProgress-bar {
      height: 1rem;
      border-radius: 0.9rem;
      background-color: ${MainColors.primary};
      flex: 1; /* Ajusta la barra de progreso para que ocupe todo el espacio disponible */
    }

    .MuiTypography-caption {
      font-size: 1rem;
      margin-left: 0; /* Elimina el margen izquierdo para que "1/5" esté pegado a la barra de progreso */
    }
  }
`;
