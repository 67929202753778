import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: center;
  .Container {
    display: flex;
    flex-direction: row;
    gap: 1rem; /* Espacio entre filas */
  }

  .Benefits {
    display: flex;

    align-items: center;
    //margin-right: 200px;
    background-color: white;
    flex-direction: column;
    width: 100%;
  }

  .Total-Points {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .Points {
    color: ${GrayScale.FullDark};
    margin-left: 5px;
    text-align: center;
  }

  .Card_Chart_Container {
    width: 80%;
    height: 80%;
    margin-right: 10px;
  }
  .Card_Container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    background-color: white;
    flex-direction: column;
    width: 20%;
    max-height: 100%;
    margin-left: 10px;
  }
  @media (min-width: 200px) and (max-width: 768px) {
    .Container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      gap: 1rem; /* Espacio entre filas */
    }

    .Benefits {
      grid-row: 1;
    }

    .Card_Container {
      grid-row: 2;
      margin-top: 20%;
      justify-content: center;
      //margin-left: 50px;
      margin-right: 50px;
      background-color: white;
      flex-direction: column;
      width: 20%;
      max-height: 100%;
      margin-left: 200px;
      justify-content: center;
    }

    .First_Card_Container {
      width: 100%;
      margin-left: 10%;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }

  .Card_Title_Text {
    margin-left: 10px;
    color: ${GrayScale.FullDark};
  }

  .Card_Content_Text_Title {
    margin-left: 10px;
    color: ${GrayScale.Gray};
  }

  .Card_Qr_Img {
    max-width: 200px;
    max-height: 200px;
  }
  .Card_Stores_Img {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    max-height: 100px;
  }

  .Button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
  }

  .First_Container {
    display: flex;
    flex-direction: row;
  }

  .First_Card_Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 1%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .Content_Title {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: -20px;
  }

  .Content_Container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 20px;
  }

  .DataGender {
    margin-top: 7px;
  }

  .Prueba {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .Second_Container {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    height: 100%;
  }

  .DB_Charts {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .DB_ChartsTwo {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    width: 40%;
  }

  .DB_Wallet {
    padding: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    width: 30%;
  }

  .DB_Datagrid {
    padding: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    width: 100%;

    &-Container {
      height: 45rem;
      width: 100%;
    }

    &-WalletContainer {
      height: 100%;
      width: 100%;
    }

    &-Title {
      margin: 1rem 0;
      text-align: start;
    }

    &-SubTitle {
      margin: 1rem 0;
      text-align: start;
    }

    &-Table {
      font-family: Poppins, system-ui, -apple-system, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
      font-size: 1.6rem; /* Tamaño de fuente predeterminado */
      border: none;
      height: 100%;

      /* Estilo para los números de la tabla (clase MuiDataGrid-cell) */
      .MuiTablePagination-displayedRows {
        font-size: 1.6rem; /* Ajusta el tamaño de fuente a tu preferencia */
      }

      .MuiDataGrid-columnHeaders {
        border: none;
      }

      &-button {
        padding: 1rem;
        margin: 0.5rem;
        border: 1.5px solid ${GrayScale.LightGray};
        border-radius: 0.5rem;

        & > img {
          width: 2rem;
        }
      }
    }
  }
`;
