import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import { FormDataType } from "../../screens/RegisterScreen/Views/RegisterScreenView";
import { User } from "../../types/app.types";
import axios from "axios";
import Api from "../../config/Api";

interface RecoverPasswordArgs {
  userEmail: string;
}

interface VerifyCodeArgs {
  userEmail: string;
  resetCode: string;
}

interface ChangePasswordArgs {
  userEmail: string;
  password: string;
  passwordConfirm: string;
}

// Acciones asíncronas
export const loginUser = createAsyncThunk(
  "authentication/loginUser",
  async (credentials: any) => {
    const { data } = await Api.post("/auth/login", credentials);
    if (data.token) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
    }
    return data;
  }
);

export const signoutUser = createAsyncThunk(
  "authentication/signoutUser",
  async (_, { rejectWithValue }) => {
    try {
      // Borrar el token de localStorage
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return { message: "Logout successful" };
    } catch (err) {
      // En caso de error, devuelve un mensaje de error rechazando la promesa
      return rejectWithValue({ message: "Logout failed" });
    }
  }
);

export const createUser = createAsyncThunk(
  "authentication/createUser",
  async (user: FormDataType) => {
    const { data } = await Api.post(
      "https://api.filtroo.co/register/newuser",
      user
    );

    if (data && data?.token && data?.user) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
    }
    return data;
  }
);

export const recoverPassword = createAsyncThunk(
  "authentication/recoverPassword",
  async ({ userEmail }: RecoverPasswordArgs) => {
    const { data } = await Api.post("/auth/send-code", { userEmail });
    return data;
  }
);

export const verifyCode = createAsyncThunk(
  "authentication/verifyCode",
  async ({ userEmail, resetCode }: VerifyCodeArgs) => {
    const { data } = await Api.post("/auth/check-code", {
      userEmail,
      resetCode,
    });
    return data;
  }
);

export const changePassword = createAsyncThunk(
  "authentication/changePassword",
  async ({ userEmail, password, passwordConfirm }: ChangePasswordArgs) => {
    const { data } = await Api.post("/auth/change-password", {
      userEmail,
      password,
      passwordConfirm,
    });
    return data;
  }
);

export const loadUserSession = createAsyncThunk(
  "authentication/loadingSession",
  async () => {
    const user = JSON.parse(localStorage.getItem("user") || "null");
    const userToken = localStorage.getItem("token") || null;

    if (userToken !== null) {
      const jwtDecoded: any = jwt_decode(userToken);
      const current_time = new Date().getTime() / 1000;
      if (current_time > jwtDecoded.exp) {
        return { userToken: null, user: null };
      }
    }

    // const credentials = await storage.getAccountCredentials(); // Obtener token

    // // No token, no autenticado
    // if (credentials.isFailure) {
    //   dispatch({ type: "notAuthenticated" });
    //   return;
    // }

    // // Hay token
    // const userDataResponse = await getAccountInfo(credentials); // Obtener datos de usuario

    // if (userDataResponse.isFailure) {
    //   await storage.removeAccountCredentials();
    //   dispatch({
    //     type: "addError",
    //     payload: userDataResponse.failure.message,
    //   });
    //   return dispatch({ type: "notAuthenticated" });
    // }

    // if (userDataResponse.isSuccess) {
    //   const { provider, accounts } = userDataResponse.success;

    //   if (!accounts[0]) {
    //     return dispatch({
    //       type: "checkAuth",
    //       payload: {
    //         status: "registered",
    //         accessToken: credentials.success.accessToken,
    //         refreshToken: credentials.success.refreshToken,
    //         user: provider as EmailAuthProviderInstance,
    //         userData: accounts[0] ?? null,
    //       },
    //     });
    //   }
    // }

    return { userToken, user };
  }
);

export const loginUserByGoogle = createAsyncThunk(
  "authentication/google",
  async (googleData: any) => {
    let response: any;
    try {
      response = await Api.post("/auth/google", googleData);
    } catch (error) {
      console.log(error);
    }

    if (response.data.token) {
      localStorage.setItem("token", response.data?.token);
      localStorage.setItem("user", JSON.stringify(response.data?.user));
    }
    return response.data;
  }
);

type SliceState = {
  isFetching: boolean;
  didInvalidate: boolean;
  user: User | null;
  token: null | string;
};

// Slice
const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isFetching: false,
    didInvalidate: false,
    user: null,
    token: null,
  } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(loginUser.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(loginUserByGoogle.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(signoutUser.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(signoutUser.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(signoutUser.fulfilled, (state) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.user = null;
        state.token = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(createUser.pending, (state, action) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
      })
      .addCase(recoverPassword.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(verifyCode.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.isFetching = false;
      })
      .addCase(loadUserSession.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(loadUserSession.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(loadUserSession.fulfilled, (state, action) => {
        if (state.token === null) {
          state.token = action.payload.userToken;
        }
        if (state.user === null) {
          state.user = action.payload.user;
        }
        state.isFetching = false;
      });
  },
});

export default authenticationSlice;
