import LoginScreenView from "./Views/LoginScreenView";
import { useAppDispatch } from "../../redux/store";
import { loginUser } from "../../redux/slices/authentication";
import { GoogleOAuthProvider } from "@react-oauth/google";

const LoginScreen = () => {
  const dispatch = useAppDispatch();

  const handleLogin = (credentials: { email: string; password: string }) => {
    //console.log("credentials", credentials);
    dispatch(loginUser(credentials));
  };

  return (
    <GoogleOAuthProvider  clientId="459237825545-11r2489s1ur04rhcncmbgplaadbf8ctp.apps.googleusercontent.com">
      <LoginScreenView handleLogin={handleLogin} />
    </GoogleOAuthProvider>
  );
};

export default LoginScreen;
