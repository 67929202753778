import { FC } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
import { ButtonPresets, TextPresets } from "../../../components";
import { Styles } from "./HomeScreenView.styles";
import { Card } from "../../../components/UI/Card";
import stores from "../../../assets/images/appstoreGooglePlay.png";
import qr from "../../../assets/images/qr.png";
import { Filter } from "../../../types/app.types";
import { useNavigate } from "react-router-dom";
import { DashboardHeader } from "../../../components/Dashboard/Header/DashboardHeader";
import cruzEstrella from "../../../assets/images/cruzEstrellas.png";
interface HomeScreenProps {
  filters: Filter[];
  onCreateFilter?: () => void;
  ganalytics?: any;
  wallet?: any;
}

const HomeScreenView: FC<HomeScreenProps> = ({
  filters,
  onCreateFilter,
  ganalytics,
  wallet,
}: HomeScreenProps) => {
  const navigate = useNavigate();
  const filtersMock: any[] = [
    {
      _id: "1",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "2",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "3",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    {
      _id: "4",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    // {
    //   _id: "5",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
  ];
  return (
    <Styles>
      <DashboardHeader sectionName="Home" />
      <div className="Container">
        <div className="Datos">
          {filters && filters.length > 0 && (
            <div className="Filters_Title">
              <TextPresets.TitleH1>Filters</TextPresets.TitleH1>
            </div>
          )}

          {filters && filters.length === 0 ? (
            <div className="Empty">
              <div>
                <img className="Rocket-Logo" src={cruzEstrella} alt="Confirm" />
              </div>
              <TextPresets.TitleH1 className="Empty__title">
                You don't have any filters yet
              </TextPresets.TitleH1>

              <TextPresets.Body className="Empty__description">
                Upload your first filter and start earning points
              </TextPresets.Body>
              <ButtonPresets.ButtonRoundedFilledPrimary
                onClick={onCreateFilter}
                label="Upload new filter"
              />
            </div>
          ) : (
            <Swiper
              modules={[Pagination]}
              pagination={{ clickable: true }}
              className="swiper-container"
              spaceBetween={20}
              slidesPerView={filters && Math.min(filters.length, 3)}
            >
              {filters &&
                filters.map((filter: Filter) => (
                  <SwiperSlide
                    key={filter._id}
                    onClick={() => navigate(`/filters/list`)}
                  >
                    {<img src={filter.image} alt="filter" />}
                  </SwiperSlide>
                ))}
            </Swiper>
          )}

          {/* <div className="Filters_Title">
          <TextPresets.TitleH1>Overview</TextPresets.TitleH1>
        </div>

        <div>
          <h1>GRAFICOS CON DATOS</h1>
        </div> */}
        </div>

        <Card className="Card_Container">
          <div className="First_Container">
            <div className="First_Card_Container">
              <div className="Content_Title">
                <TextPresets.TitleH1>Download Filtroo</TextPresets.TitleH1>
              </div>
              <div>
                <TextPresets.TitleH2>
                  Turn your TikTok filters into profit
                </TextPresets.TitleH2>
              </div>
              <div>
                <img className="Card_Qr_Img" src={qr} alt={`QR`} />
              </div>
              <div>
                <img
                  className="Card_Stores_Img"
                  src={stores}
                  alt={`Google Play & App Store`}
                />
              </div>
            </div>
          </div>
          {filters && filters.length > 0 && (
            <div className="Button">
              <ButtonPresets.ButtonRoundedFilledPrimary
                onClick={onCreateFilter}
                label="Upload new filter"
              />
            </div>
          )}
        </Card>
      </div>
    </Styles>
  );
};

export default HomeScreenView;
