import styled from "styled-components";

export const StyledDashboardHeader = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  margin-bottom: 1.5rem;

  .DBHeader {
    &__BreadCrumb {
      text-align: left;

      span {
        font-weight: 300;
        cursor: pointer;
      }
    }

    &__ToolBar {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      &-Left {
        display: flex;
      }

      &-Center {
        display: flex;
        flex: 1;
      }

      &-Right {
        display: flex;
      }
    }
  }
`;
