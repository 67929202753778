import { forwardRef } from "react";
import { SingleSelect, SingleSelectProps } from "../SingleSelect";
import styled from "styled-components";
import { GrayScale } from "../../../../assets/theme/Colors";
import cn from "classnames";

const StyledSelectInput = styled(SingleSelect)`
  .SelectInput {
    border-radius: 3rem;
    background-color: ${GrayScale.FullLight};
    margin: 0.5rem 0;

    & .MuiSelect-select {
      padding: 0.5rem 2rem;
    }
  }

  .SelectInput > fieldset {
    border: 0;
  }
`;

export const SelectInputRounded = forwardRef<
  HTMLInputElement,
  SingleSelectProps
>(({ className, ...props }: SingleSelectProps, ref) => {
  return (
    <StyledSelectInput
      selectProps={{
        className: cn("SelectInput", className),
        name: props.name,
      }}
      {...props}
      ref={ref}
    />
  );
});
SelectInputRounded.displayName = "SelectInput";
