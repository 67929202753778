import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors";
import { TextPresets } from "../../../components";

export const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    max-height: 700px;
    max-width: 1550px;
  }

  .Empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__title {
      color: ${GrayScale.darkOverLight};
      font-weight: 700;
      line-height: 1.5;

      margin-bottom: 1rem;
    }

    &__description {
      color: ${GrayScale.darkOverLight};
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 1rem;

      max-width: 500px;
    }

    &__button {
      margin-top: 1rem;
      width: 100%;
      max-width: 200px;
      height: 50px;
      max-height: 50px;
    }
    &__NotFilterLogo {
      margin-top: 10%;

      max-width: 100px;
      max-height: 100px;
    }
  }
`;
