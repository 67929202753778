import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";
const VerticalCarousel = ({ images }: any) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2%",
        maxWidth: "100%", //100%
        maxHeight: "200px",
      }}
    >
      <Swiper
        modules={[Autoplay]}
        slidesPerView={2}
        spaceBetween={30}
        loop={true}
        direction="vertical"
        autoplay={true}
      >
        {images.map((imageUrl: any, index: any) => (
          <SwiperSlide
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2%",
              maxWidth: "100%",
              maxHeight: "100%",
              padding: "10px",
            }}
            key={index}
          >
            <img
              src={imageUrl}
              alt={`Slide ${index}`}
              style={{ maxWidth: "100%", maxHeight: "350px" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default VerticalCarousel;
