import styled from "styled-components";
import { TextPresets } from "../../Text";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { GrayScale, MainColors } from "../../../../assets/theme/Colors";

export interface LinearProgressLabelInProps extends LinearProgressProps {
  value: number;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 25,
  borderRadius: 12.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: GrayScale.LightGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 12.5,
    backgroundColor: MainColors.primary,
  },
}));

const Styles = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .LinearProgress {
    &__Container {
      width: 100%;
    }

    &__Label {
      position: absolute;
      right: 0;
      margin-right: 1rem;
    }
  }
`;

export const LinearProgressLabelIn = ({
  value,
  ...props
}: LinearProgressLabelInProps) => {
  return (
    <Styles>
      <div className="LinearProgress__Container">
        <BorderLinearProgress variant="determinate" value={value} {...props} />
      </div>
      <div className="LinearProgress__Label">
        {value && (
          <TextPresets.Body>{`${Math.round(value)}%`}</TextPresets.Body>
        )}
      </div>
    </Styles>
  );
};
