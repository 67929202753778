import styled from "styled-components";
import { TextPresets } from "../../../components";
import { LinearProgressDouble } from "../../../components/UI/LinearProgress/presets";
import { GrayScale } from "../../../assets/theme/Colors";

export interface InsightItemProps extends React.HTMLAttributes<HTMLDivElement> {
  rangeFrom?: number;
  rangeTo?: number;
  title: string;
  type?: "range" | "value";
  value?: number | React.ReactNode;
  secondaryValue?: number;
  LinearProgressClassName?: string;
  SecondaryLinearProgressClassName?: string;
}

const StyledContainer = styled.div`
  margin: 3rem 0;
  display: flex;
  flex-direction: column;

  .Item {
    &-Title {
      margin-bottom: 0.5rem;
    }

    &-Range {
      text-align: start;
      margin: 0.2rem 0;
    }

    &-Text {
      white-space: nowrap;
      text-align: start;
      margin: 0.2rem 0;
      background-color: ${GrayScale.lightOverDark};
      border-radius: 1.2rem;
      text-align: center;
    }
  }
`;

export const InsightItem = ({
  title,
  type = "value",
  rangeFrom,
  rangeTo,
  value,
  LinearProgressClassName,
  secondaryValue,
  SecondaryLinearProgressClassName,
  ...props
}: InsightItemProps) => {
  const formattedValue = (function () {
    if (!value) {
      return null;
    }

    if (typeof value === "string") {
      return (
        <TextPresets.TitleH2 className="Item-Text">{value}</TextPresets.TitleH2>
      );
    }

    return value;
  })();

  return (
    <StyledContainer {...props}>
      <div className="Item-Title">
        <TextPresets.Body>{title}</TextPresets.Body>
      </div>

      {(type === "range" && rangeFrom && rangeTo && (
        <>
          <TextPresets.TitleH2 className="Item-Range">
            {`${getNumberScale(rangeFrom).value}${
              getNumberScale(rangeFrom).measure
            } - ${getNumberScale(rangeTo).value}${
              getNumberScale(rangeTo).measure
            }`}
          </TextPresets.TitleH2>
          <LinearProgressDouble
            className={LinearProgressClassName}
            // secondaryLinearProps={{
            //   className: SecondaryLinearProgressClassName,
            // }}
            value={45}
            // secundaryValue={secondaryValue ?? 75}
          />
        </>
      )) ||
        null}

      {type === "range" && (!rangeFrom || !rangeTo) && (
        <>
          <TextPresets.TitleH2 className="Item-Range">-</TextPresets.TitleH2>
          <LinearProgressDouble
            className={LinearProgressClassName}
            // secondaryLinearProps={{
            //   className: SecondaryLinearProgressClassName,
            // }}
            value={0}
            // secundaryValue={0}
          />
        </>
      )}

      {type === "value" && formattedValue}
    </StyledContainer>
  );
};

function getNumberScale(number: number) {
  if (number >= 1000000) {
    return { value: (number / 1000000).toFixed(1), measure: "M" };
  } else if (number >= 1000) {
    return { value: (number / 1000).toFixed(1), measure: "K" };
  } else {
    return { value: number, measure: "" };
  }
}
