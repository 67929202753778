import cn from "classnames";
import { TextPresets } from "../../UI";
import { StyledDashboardHeader } from "./DashboardHeader.styles";

export interface DashboardHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  sectionName: string;
  currentRoute?: string;
  LeftComponents?: React.ReactNode;
  RightComponents?: React.ReactNode;
  CenterComponents?: React.ReactNode;
  ContainersClassNames?: {
    Main?: string;
    Left?: string;
    Center?: string;
    Right?: string;
  };
  onSectionClick?: () => void;
}

export const DashboardHeader = ({
  sectionName,
  currentRoute = "Overview",
  LeftComponents,
  RightComponents,
  CenterComponents,
  ContainersClassNames,
  onSectionClick,
  ...props
}: DashboardHeaderProps) => {
  return (
    <StyledDashboardHeader {...props}>
      <TextPresets.TitleH1 className="DBHeader__BreadCrumb">
        {currentRoute !== "" ? (
          <>
            <span onClick={onSectionClick}>{sectionName} |</span> {currentRoute}{" "}
          </>
        ) : (
          <span onClick={onSectionClick}>{sectionName}</span>
        )}
      </TextPresets.TitleH1>
      <div className={cn("DBHeader__ToolBar", ContainersClassNames?.Main)}>
        {LeftComponents && (
          <div
            className={cn("DBHeader__ToolBar-Left", ContainersClassNames?.Left)}
          >
            {LeftComponents}
          </div>
        )}
        {CenterComponents && (
          <div
            className={cn(
              "DBHeader__ToolBar-Center",
              ContainersClassNames?.Center
            )}
          >
            {CenterComponents}
          </div>
        )}
        {RightComponents && (
          <div
            className={cn(
              "DBHeader__ToolBar-Right",
              ContainersClassNames?.Right
            )}
          >
            {RightComponents}
          </div>
        )}
      </div>
    </StyledDashboardHeader>
  );
};
