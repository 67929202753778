import styled from "styled-components";

export const Styles = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  .Title {
  }

  .Stepper {
    max-width: 35%;
    padding: 0;
    margin-right: 0.5rem;

    &__Actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-RightButton {
        font-size: 3.5rem;
      }
    }

    & .MuiMobileStepper-progress {
      height: 0.5rem;
      border-radius: 0.9rem;
      width: 100%;
    }

    & .MuiLinearProgress-bar {
      height: 0.5rem;
      border-radius: 0.9rem;
    }
  }

  .Steps {
    &__Content {
      flex: 1;
      display: flex;
      gap: 2rem;
    }

    &__Description {
      flex: 1;
    }
  }

  .ChatContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2rem;
    margin: 3rem 2rem;

    &-Icon {
      margin-right: 0.5rem;
    }
  }

  .CardConteiner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;
    width: 100%;
    max-width: 35rem;
    margin: 0 auto;
  }

  .Input {
    width: 100%;
    margin: 0.2rem 0;
    background-color: #f5f5f5;
  }

  .SelectInputBg {
    width: 100%;
    margin: 0.2rem 0;
    background-color: #f5f5f5;
  }

  .SelectInputRounded {
    color: #6e6b6b;
  }
  .AdminUploadFilterScreen__Content-result--success {
    color: #4caf50;
  }
  .AdminUploadFilterScreen__Content-result--error {
    color: #f44336;
  }
`;
