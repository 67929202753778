import { FC } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { useNavigate } from "react-router-dom";

import { DashboardHeader } from "../../../components/Dashboard/Header/DashboardHeader";

import { ScreenLoader } from "../../../components/UI/Spinners/presets";

import { Filter } from "../../../types/app.types";
import { StyledStep } from "./FiltersScreenView.styles";
import { ButtonPresets, TextPresets } from "../../../components";
import notFilter from "../../../assets/images/notFiltersFound.png";
import HorizontalCarousel from "../../../components/Carousel/HorizontalCarousel";

interface FiltersScreenViewProps {
  filters: Filter[];
  isLoading?: boolean;
  navigate: ReturnType<typeof useNavigate>;
}

const FiltersScreenView: FC<FiltersScreenViewProps> = ({
  filters,
  isLoading,
  navigate,
}) => {
  if (isLoading) {
    return <ScreenLoader />;
  }

  const filtersMock: any[] = [
    {
      _id: "1",
      name: "Filter 1",
      image:
        "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    },
    // {
    //   _id: "2",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
    // {
    //   _id: "3",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
    // {
    //   _id: "4",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
    // {
    //   _id: "5",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
    // {
    //   _id: "6",
    //   name: "Filter 1",
    //   image:
    //     "http://res.cloudinary.com/doz1oxe0e/image/upload/v1697630025/filtroo/filters/ohzhywqmiauno4ornhcb.png",
    // },
  ];
  return (
    <StyledStep>
      <DashboardHeader sectionName="Filters" currentRoute="List" />

      <div className="container">
        {filters && filters.length === 0 ? (
          <div className="Empty">
            <div>
              <img
                className="Empty__NotFilterLogo"
                src={notFilter}
                alt="Confirm"
              />
            </div>
            <TextPresets.TitleH1 className="Empty__title">
              No filters found
            </TextPresets.TitleH1>
            <TextPresets.TitleH3 className="Empty__description">
              You can upload your first filter by clicking on the button below
            </TextPresets.TitleH3>
            <ButtonPresets.ButtonRoundedFilledPrimary
              className="Empty__button"
              onClick={() => navigate("/filters/upload")}
              label="Upload filter"
            />
          </div>
        ) : (
          <HorizontalCarousel images={filters} navigate={navigate} />
        )}
      </div>
    </StyledStep>
  );
};

export default FiltersScreenView;
