import axios from "axios";

// const baseURL = "http://localhost:4000";
// const baseURL = 'http://10.0.2.2:4050';
//const baseURL = "https://backendfiltr-ln2myb7c7q-ue.a.run.app";

const baseURL = "https://api.crm.filtroo.co";
// //const baseURL = "https://api.filtroo.co";

const Api = axios.create({ baseURL });

Api.interceptors.request.use(async (config: any) => {
  const token = localStorage?.getItem("token");
  let user = "null"
  if(localStorage?.getItem("user") && localStorage?.getItem("user") !== "undefined"){
    user = JSON.parse(localStorage?.getItem("user") || "null");
  }

  if (token && config.headers) {
    config.headers["x-auth-token"] = token;
    config.headers["x-auth-user"] = user;
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

export default Api;
