import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

let mobile = false;
if (window.innerWidth <= 768) {
  mobile = true;
}
const initialState = mobile;

export const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {},
});
export default mobileSlice;
export const selectMobile = (state: RootState) => state;
