import { FC } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "../screens";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import { PrivateRoute } from "./privateRoutes";
import { AuthLayout, LayoutFiltroo } from "../components/Layouts";
import RegisterScreen from "../screens/RegisterScreen/RegisterScreen";
import UploadFilterScreen from "../screens/UploadFilterScreen/UploadFilterScreen";
import UploadFilterScreenView from "../screens/UploadFilterScreen/Views/UploadFilterScreenView";
import { Filters } from "../screens/FiltersScreen";
import FilterDetailScreen from "../screens/FilterDetailScreen/FiltersDetailScreen";
import WalletScreen from "../screens/WalletScreen/WalletScreen";

interface FiltroRoutesProps {
  userToken: string | null;
}

const FiltroRoutes: FC<FiltroRoutesProps> = ({ userToken }) => {
  if (!userToken) {
    return LoginRouter();
  }

  return (
    <Routes>
      <Route element={<LayoutFiltroo />}>
        <Route path="home" element={<PrivateRoute children={<Home />} />} />

        <Route
          path="filters/create"
          element={<PrivateRoute children={<UploadFilterScreen />} />}
        />

        <Route
          path="filters/upload"
          element={
            <PrivateRoute
              children={
                <UploadFilterScreenView
                  submitting={false}
                  setSubmitting={function (submitting: boolean): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              }
            />
          }
        />

        <Route
          path="filters/list"
          element={<PrivateRoute children={<Filters />} />}
        />

        <Route
          path="filters/wallet"
          element={<PrivateRoute children={<WalletScreen />} />}
        />

        <Route
          path="filters/:id"
          element={<PrivateRoute children={<FilterDetailScreen />} />}
        />

        <Route path="*" element={<Navigate replace to="/home" />} />
      </Route>
    </Routes>
  );
};

function LoginRouter() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/create-account" element={<RegisterScreen />} />
        <Route path="*" element={<Navigate replace to="/login" />} />
      </Route>
    </Routes>
  );
}

export default FiltroRoutes;
