import styled from "styled-components";
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import { GrayScale, MainColors } from "../../../../assets/theme/Colors";

export interface LinearProgressDoubleProps extends LinearProgressProps {
  value: number;
  secundaryValue?: number;
  secondaryLinearProps?: LinearProgressProps;
}

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 25,
  borderRadius: 12.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "transparent",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 12.5,
    backgroundColor: MainColors.primary,
  },
}));

const SecondaryBorderLinearProgress = styled(LinearProgress)(() => ({
  height: 25,
  borderRadius: 12.5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: GrayScale.LightGray,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 12.5,
    backgroundColor: MainColors.gold,
  },
}));

const Styles = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .LinearProgress {
    &__Container {
      width: 100%;
      z-index: 1;
    }

    &__Secondary {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
`;

export const LinearProgressDouble = ({
  value,
  secundaryValue = 0,
  secondaryLinearProps,
  ...props
}: LinearProgressDoubleProps) => {
  return (
    <Styles>
      <div className="LinearProgress__Container">
        <BorderLinearProgress variant="determinate" value={value} {...props} />
      </div>
      <div className="LinearProgress__Secondary">
        <SecondaryBorderLinearProgress
          variant="determinate"
          value={secundaryValue}
          {...secondaryLinearProps}
        />
      </div>
    </Styles>
  );
};
