import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Ganalytics } from "../../types/app.types";
import { PayloadAction } from "@reduxjs/toolkit";
import Api from "../../config/Api";

export const getOneAnalyticsForCampaign = createAsyncThunk(
  "ganalytics/getOneAnalyticsForCampaign",
  async (userId: string | undefined) => {
    const { data } = await Api.get(
      `/analytics/getoneanalyticforcampaign/${userId}`
    );
    return data;
  }
);

export const getTotalAnalyticsForCampaign = createAsyncThunk(
  "ganalytics/getTotalAnalyticsForCampaign", 
  async (userId: string | undefined, { getState }) => {
    const state = await getState() as any
    if(!state.isFetching){
      const { data } = await Api.get(
        `/analytics/gettotalanalyticforcampaign/${userId}`
      );
      return data;
    }
  }
);

type SliceState = {
  isFetching: boolean;
  didInvalidate: boolean;
  campaignWithAnalytics: Ganalytics | null;
  allGanalyticsCampaign: Ganalytics | null;
};

// Slice
const ganalyticsSlice = createSlice({
  name: "ganalytics",
  initialState: {
    isFetching: false,
    didInvalidate: false,
    campaignWithAnalytics: null,
    allGanalyticsCampaign: null,
  } as SliceState,
  reducers: {
    setGanalyticsData: (state, action: PayloadAction<Ganalytics | null>) => {
      state.campaignWithAnalytics = action.payload;
    },
    setAllGanalyticsData: (state, action: PayloadAction<Ganalytics | null>) => {
      console.log(action.payload)
      state.allGanalyticsCampaign = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneAnalyticsForCampaign.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(getOneAnalyticsForCampaign.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(getOneAnalyticsForCampaign.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.campaignWithAnalytics = action.payload;
      })
      .addCase(getTotalAnalyticsForCampaign.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
      })
      .addCase(getTotalAnalyticsForCampaign.rejected, (state) => {
        state.isFetching = false;
        state.didInvalidate = true;
      })
      .addCase(getTotalAnalyticsForCampaign.fulfilled, (state, action) => {
        console.log(action.payload)
        state.campaignWithAnalytics = action.payload.campaigns
        state.allGanalyticsCampaign = action.payload.totalMetrics
        state.isFetching = false;
        state.didInvalidate = false;
      });
  },
});

export default ganalyticsSlice;
