import { useEffect, useState } from "react";
import Api from "../config/Api";
import { Filter } from "../types/app.types";

export const useGetFilterDetail = (filterId: string) => {
  const [data, setdata] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const [error, seterror] = useState(false);

  useEffect(() => {
    getFilterDetail(filterId);
  }, []);

  const refreshFilterDetail = async () => {
    await getFilterDetail(filterId);
  };

  const getFilterDetail = async (filterId: string) => {
    setIsLoading(true);
    try {
      const { data } = await Api.get<Filter[]>(
        `https://api.crm.filtroo.co/filters/details/${filterId}`
      );

      setdata(data);
      setIsLoading(false);
    } catch (error) {
      seterror(true);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    filter: data.filter,
    error,
    getFilterDetail,
    refreshFilterDetail,
  };
};
