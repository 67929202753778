import { FC } from "react";

import { useParams } from "react-router-dom";
import FiltersScreenDetailView from "./Views/FiltersDetailScreenView";

import { useGetFilterDetail } from "../../hooks/useGetFilterDetail";

const FilterDetailScreen: FC = () => {
  const { id: filterId } = useParams();

  const { filter, isLoading } = useGetFilterDetail(filterId!);

  return <FiltersScreenDetailView filter={filter} isLoading={isLoading} />;
};

export default FilterDetailScreen;
