import styled from "styled-components";
import { ButtonPresets } from "../../UI/Button";
import { TextPresets } from "../../UI/Text";
import ConfirmAccountLogo from "../../../assets/logos/CheckAccountLogo.png";
import ChatbotIcon from "../../../assets/icons/chatbot.png";
import { useNavigate } from "react-router-dom";

export interface ConfirmAccountStepProps {
  handleNext: () => void;
}

const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-Logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
      }

      &-Title {
        margin: 0;
      }
    }

    &__Actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
      }

      &-Separator {
        margin: 1rem 0;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const ConfirmAccountStep = ({
  handleNext,
  ...props
}: ConfirmAccountStepProps) => {
  const navigate = useNavigate();
  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <div className="Step__Content-Logo">
            <img src={ConfirmAccountLogo} alt="Confirm" />
          </div>

          <TextPresets.TitleH2 className="Step__Content-Title">
            Account created succesfully,
          </TextPresets.TitleH2>
          <TextPresets.TitleH2 className="Step__Content-Title">
            Welcome to Filtroo!
          </TextPresets.TitleH2>
        </div>
        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Login"
            onClick={() => navigate("/login")}
          />
          <TextPresets.Body className="Step__Actions-Separator">
            or
          </TextPresets.Body>
          <ButtonPresets.ButtonText
            className="Step__Actions-Button"
            label="Manage more accounts"
            onClick={() => navigate("/register")}
          />
        </div>
      </div>
      <div className="Step__ChatContainer">
        {/* <ButtonPresets.ButtonTextPrimary className="Step__ChatContainer-Button">
          <img
            src={ChatbotIcon}
            alt="chatbot"
            className="Step__ChatContainer-Icon"
          />
          Message chatbot
        </ButtonPresets.ButtonTextPrimary> */}
      </div>
    </StyledStep>
  );
};
