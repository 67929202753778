import { useState, ChangeEvent, FormEvent } from "react";
import { TextPresets } from "../../../components/UI/Text";
import { InputPresets } from "../../../components/UI/Input";
import { ButtonPresets } from "../../../components/UI/Button";
import { Styles } from "./LoginScreenView.styles";
import { useNavigate } from "react-router-dom";
//import { checkServerStatus } from "../../../redux/slices/testServer";
import { useAppDispatch } from "../../../redux/store";
import {
  loginUser,
  loginUserByGoogle,
} from "../../../redux/slices/authentication";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { RegisterDataGoogle } from "../../../types/app.types";
import Api from "../../../config/Api";
import { Modal } from "@mui/material";
import { ButtonFilledPrimary } from "../../../components/UI/Button/presets";
import googleIcon from "../../../assets/icons/google.png";
export interface LoginScreenViewProps {
  handleLogin?: (data: { email: string; password: string }) => void;
}

const LoginScreenView = ({ handleLogin }: LoginScreenViewProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [sendingForm, setSendingForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const handleSubmit = async (e: FormEvent) => {
    if (sendingForm) {
      return;
    }
    setSendingForm(true);

    if (!email || !password) {
      setError("All fields are required");
      setSendingForm(false);

      return;
    }

    try {
      const loginDispatch = await dispatch(
        loginUser({ email, password })
      ).unwrap();

      if (loginDispatch.token) {
        setSendingForm(false);

        navigate("/home");
      } else {
        console.error("No token received after login");
        setSendingForm(false);

        setError("Incorrect Login. Verify credentials");
      }
    } catch (error) {
      console.error("Error al iniciar sesión: ", error);
      setSendingForm(false);

      setError("Incorrect Login. Verify credentials");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  // const signInGoogle = async ({
  //   name,
  //   email,
  //   givenName,
  //   picture: photo,
  // }: RegisterDataGoogle): Promise<{
  //   googleLogin: boolean;
  //   created: boolean;
  //   data?: null;
  // }> => {
  //   try {
  //     //console.log("execute sign in google");
  //     //console.log("givenName", givenName);
  //     const res = await Api.post("https://api.filtroo.co/register/google", {
  //       name,
  //       email,
  //       givenName,
  //       photo:
  //         photo ??
  //         "https://icon-library.com/images/white-profile-icon/white-profile-icon-6.jpg",
  //       platform: "web",
  //     }).catch((error) => {
  //       console.log("error", error);
  //       return {
  //         googleLogin: false,
  //         created: false,
  //         data: null,
  //       };
  //     });

  //     if (res.data === null) {
  //       return {
  //         googleLogin: false,
  //         created: false,
  //         data: null,
  //       };
  //     }

  //     if (res.data.user) {
  //       const { token, user } = res.data;
  //       localStorage.setItem("token", token);
  //       localStorage.setItem("user", JSON.stringify(user));

  //       return {
  //         created: false,
  //         googleLogin: true,
  //       };
  //     } else {
  //       return {
  //         created: true,
  //         googleLogin: true,
  //       };
  //     }
  //   } catch (error: any) {
  //     console.error("error in sign in google:", error);
  //     return {
  //       googleLogin: false,
  //       created: false,
  //       data: null,
  //     };
  //   }
  // };

  const responseMessage = async (response: any) => {
    await axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${response.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: "application/json",
          },
        }
      )
      .then(async (res: any) => {
        if (res.data) {
          try {
            const loginDispatch = await dispatch(
              loginUserByGoogle({ ...res.data })
            ).unwrap();

            console.log(res.data);
            if (loginDispatch?.token === null && loginDispatch?.user === null) {
              navigate("/create-account", {
                state: { infoGoogleUser: res.data },
              });
            }

            // if (loginDispatch) {
            //   setSendingForm(false);
            //   navigate("/home");
            // } else {
            //   console.error("No token received after login");
            //   setSendingForm(false);

            //   setError("Incorrect Login. Verify credentials");
            // }
          } catch (error) {
            console.log(error);
            setModalMessage("Oops, looks like you've joined with this email!");
            setShowModal(true);
          }
        }
      });

    // const createUser = await signInGoogle({
    //   name: infoGoogleUser!.name,
    //   email: infoGoogleUser!.email,
    //   givenName: infoGoogleUser!.given_name,
    //   picture: infoGoogleUser!.picture,
    // });

    // if (createUser.data === null) {
    //   setModalMessage("Oops, looks like you've joined with this email!");
    //   setShowModal(true);
    //   return;
    // }

    // //navegar a la create-account step 2

    // if (createUser.created) {
    //   navigate("/create-account", { state: { infoGoogleUser } });
    // }
  };

  const login = useGoogleLogin({
    //@ts-ignore
    onSuccess: (codeResponse) => {
      responseMessage(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <Styles>
      <TextPresets.TitleH2 className="Login__Form-Title">
        Log in to your account
      </TextPresets.TitleH2>
      <InputPresets.TextInput
        containerProps={{ className: "Login__Form-Input" }}
        placeholder="Email"
        value={email}
        onChange={handleEmailChange}
      />
      <InputPresets.PasswordInput
        containerProps={{ className: "Login__Form-Input" }}
        placeholder="Password"
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
      />
      {error && <p style={{ color: "red" }}>{error}</p>}

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "2rem",
        }}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setModalMessage("");
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            width: "100%",
            height: "13rem",
            maxWidth: "30rem",
            borderRadius: "2rem",
            padding: "4rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#3D3D3D",
              textAlign: "center",
            }}
          >
            <p>{modalMessage}</p>
            <ButtonFilledPrimary
              style={{ borderRadius: "2rem" }}
              className="Login__Form-Actions-SignUp"
              label="Close"
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      </Modal>

      {/* <div className="Login__Form-RememberAction">
        <Checkbox
          containerProps={{
            className: "Login__Form-RememberAction-Checkbox",
          }}
          label="Remember password"
        />
        <ButtonPresets.ButtonTextPrimary
          className="Login__Form-RememberAction-Button"
          label="Forgot my password"
        />
      </div> */}
      <div className="Login__Form-Actions">
        <ButtonPresets.ButtonRoundedFilledPrimary
          className="Login__Form-Actions-Login"
          label="Log in"
          loading={sendingForm}
          onClick={handleSubmit}
        />

        <div className="Login__Form-Actions-TextContainer">
          <ButtonPresets.ButtonRoundedFilledPrimary
            onClick={() => login()}
            className="Login__Form-Actions-Google"
          >
            {/* Contenido personalizado del botón de Google */}
            <div className="google-button-content">
              <img className="google-icon" src={googleIcon} alt="Google Icon" />
              <span>Sign in with Google</span>
            </div>
          </ButtonPresets.ButtonRoundedFilledPrimary>
        </div>
        <div className="Login__Form-Actions-TextContainer">
          <TextPresets.BodySmall className="Login__Form-Actions-Text">
            Don't have an account?
          </TextPresets.BodySmall>
          <ButtonPresets.ButtonTextPrimary
            className="Login__Form-Actions-SignUp"
            label="Sign up"
            onClick={() => navigate("/create-account")}
          />
        </div>
      </div>
    </Styles>
  );
};

export default LoginScreenView;
