import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors";
import { TextPresets } from "../../../components";

export const Styles = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1%;
  height: 100%;

  .Container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;

    //margin-top: -5%;
  }

  .Datos {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 780px;
  }
  /* 
  .Filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */

  .Filters_Title {
    display: flex;
    width: 100%;
    justify-content: left;
  }

  .Empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    &__title {
      color: ${GrayScale.darkOverLight};
      font-weight: 700;
      line-height: 1.5;

      margin-bottom: 1rem;
    }

    &__description {
      color: ${GrayScale.darkOverLight};
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 1rem;

      max-width: 500px;
    }
  }

  .Rocket-Logo {
    margin-top: 10%;

    max-width: 100px;
    max-height: 100px;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-container {
    width: 100%;
    height: 700px;
  }

  /* .container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 100%;
  } */
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    margin-right: 10px;
    max-width: 240px;
    max-height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .swiper-pagination-horizontal {
    margin-bottom: 100px;
  }

  /* .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

     Center slide text vertically 
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }*/

  .card {
    border: 2px solid #e0e0e0;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
    width: 100%;
    margin: 0 auto;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    :hover {
      background: #00aaff;
    }
  }

  .card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
    transition: transform 0.3s;
  }
  .card__content {
    width: 100%;
    //padding: 1rem;
    flex-direction: column;
    border-radius: 1rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    //margin-bottom: 132px;
  }

  .Card_Container {
    display: flex;
    justify-content: right;
    align-items: right;
    // margin-top: 9%;
    background-color: hsl(300, 8%, 97%);
    flex-direction: column;
    max-width: 100%;
    max-height: 100%;
    margin-right: 2%;
  }

  @media (min-width: 200px) and (max-width: 768px) {
    .Benefits {
      grid-row: 1;
    }

    .Card_Container {
      grid-row: 2;
      //margin-top: -5%;
      justify-content: center;

      background-color: white;
      flex-direction: column;
      width: 40%;
      min-width: 200px;

      //margin-left: 150px;
    }

    .First_Card_Container {
      width: 100%;
      // margin-left: 10%;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    .Card_Qr_Img {
      max-width: 100px;
      max-height: 100px;
    }
    .Card_Stores_Img {
      margin-top: 10px;
      width: 100%;
      height: 100%;
      max-height: 100px;
    }
  }

  .Card_Title_Text {
    margin-left: 10px;
    color: ${GrayScale.FullDark};
  }

  .Card_Content_Text_Title {
    margin-left: 10px;
    color: ${GrayScale.Gray};
  }

  .Card_Qr_Img {
    max-width: 200px;
    max-height: 200px;
  }
  .Card_Stores_Img {
    margin-top: 10px;
  }

  .Button {
    display: flex;
    justify-content: center;
    margin-top: 2%;

    max-height: 50px;
  }

  .First_Container {
    display: flex;
    flex-direction: row;
  }

  .First_Card_Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 1%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .Content_Title {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: -20px;
  }

  .Content_Container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 20px;
  }
`;
