import styled from "styled-components";
import { GrayScale } from "../../../assets/theme/Colors";

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .General {
    &__Container {
      flex: 1;
      display: flex;
      gap: 2rem;
      margin-bottom: 1rem;
      padding: 1.5rem;
      justify-content: center;
    }
  }
  .Card {
    &__Description {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1.5rem;
      background-color: ${GrayScale.LightGray};
      max-width: 150rem;
      border-radius: 2.5rem;
      margin-top: 2.5rem;
      align-items: first baseline;
    }
  }
  .Insights {
    &__Container {
      display: flex;
      gap: 2rem;
      margin-bottom: 1rem;
      padding: 1.5rem;
    }

    &__ImageContainer {
      flex: 1;
      text-align: center;

      &-Image {
        object-fit: cover;
        height: 60rem;
        border-radius: 1.5rem;
        width: 100%;
        max-width: 35rem;
      }

      &-Empty {
        width: 100%;
        height: 60rem;
        background-color: ${GrayScale.LightGray};
        border-radius: 1.5rem;
      }
    }

    &__Content {
      flex: 1;

      &-Title {
        text-align: start;
        margin: 1.2rem 0;
        font-weight: 600;
        background-color: ${GrayScale.Gray};
        border-radius: 1.5rem;
        padding: 1.5rem;

        text-align: center;
      }
      &-Item {
        display: flex;
        gap: 0.2rem;
        align-items: center;

        &-text {
          margin: 0;
        }

        &-icon {
          width: 3rem;
          height: 3rem;
        }
      }
      &-Url {
        word-break: break-all;
        color: #007bff;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__Legend {
      text-align: center;
    }

    .GoBack {
      background-color: transparent;
      max-height: 6rem;
      margin-top: auto;

      &__LeftIcon {
        margin-right: 0.5rem;
      }
    }
  }
`;

export const StyledLink = styled.a`
  word-break: break-all;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  background-color: ${GrayScale.lightOverDark};
  border-radius: 1rem;
  height: 4rem;
  text-align: center;
  align-items: center;
  padding: 0.5rem 1rem;
`;
