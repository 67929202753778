import RegisterScreenView, { FormDataType } from "./Views/RegisterScreenView";

import { createUser } from "../../redux/slices/authentication";
import { useAppDispatch } from "../../redux/store";
import { useState } from "react";

const RegisterScreen = () => {
  const dispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [userCreated, setUserCreated] = useState(false);

  const handleCreateUser = async (formData: FormDataType): Promise<boolean> => {
    try {
      const body = {
        name: formData.detailsStep.name,
        password: formData.emailPasswordStep.password,
        email: formData.emailPasswordStep.email,
        phone: formData.detailsStep.phone,
        birthday: formData.detailsStep.birthday,
        country: formData.detailsStep.country,
        platform: "web",
        provider: formData.providerType,
        image:
          formData.profileImage ??
          "https://icon-library.com/images/white-profile-icon/white-profile-icon-6.jpg",
      };
      //@ts-ignore
      return await dispatch(createUser(body)).then((result) => {
        if (result.type === "authentication/createUser/rejected") {
          return false;
        } else {
          setUserCreated(true);
          return true;
        }
      }); // unwrap() rechaza la promesa si la acción es rechazada
    } catch (error) {
      console.error("Error al crear usuario:", error);
      return false;
    }
  };

  return (
    <RegisterScreenView
      handleCreateUser={handleCreateUser}
      submitting={submitting}
      setSubmitting={setSubmitting}
      userCreated={userCreated}
    />
  );
};

export default RegisterScreen;
