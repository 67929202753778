import { useAppDispatch } from "../../../redux/store";
import { signoutUser } from "../../../redux/slices/authentication";
import LayoutFiltrooView from "./LayoutFiltrooView";

interface LayoutProps {
  children?: React.ReactNode;
}

const LayoutFiltroo = ({ children }: LayoutProps) => {
  const dispatch = useAppDispatch();
  const handleSignout = async () => {
    try {
      await dispatch(signoutUser()).unwrap();
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };

  return (
    <LayoutFiltrooView handleSignout={handleSignout}>
      {children}
    </LayoutFiltrooView>
  );
};

export default LayoutFiltroo;
