import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import Api from "../../config/Api";

export const syncWallet = createAsyncThunk("wallet/sync", async () => {
    const { data } = await Api.get("/wallets/balance")
    return data
    })

export const getWallethistory = createAsyncThunk("wallet/history", async () => {
  const { data } = await Api.get("/wallets/balance/history")
  return data
  })
const initialState = {
    isFetching: false,
    name: "",
    pointsBalance: 0,
    transactions: []
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(syncWallet.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(syncWallet.fulfilled, (state, action) => {
        state.name = action.payload.name
        state.pointsBalance = action.payload.pointsBalance
        state.isFetching = false;
      })
      .addCase(syncWallet.rejected, (state, action) => {
        state.isFetching = false;
      })
      .addCase(getWallethistory.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(getWallethistory.fulfilled, (state, action) => {
        state.transactions = action.payload.transactions
        state.isFetching = false;
      })
      .addCase(getWallethistory.rejected, (state, action) => {
        state.isFetching = false;
      })
    }
});
export default walletSlice;

export const selectWallet = (state: RootState) => state;
