import { ButtonPresets } from "../../UI/Button";
import ChatbotIcon from "../../../assets/icons/chatbot.png";
import { FormDataType } from "../../../screens/RegisterScreen/Views/RegisterScreenView";
import { InputPresets } from "../../UI/Input";
import { TextPresets } from "../../UI/Text";
import styled from "styled-components";
import { useState } from "react";
import Joi from "joi";

const StyledStep = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .Step {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    max-width: 48rem;
    margin: 0 auto;

    &__Content {
      flex: 1;
      width: 100%;

      &-Input {
        width: 100%;
        margin: 0.2rem 0;
      }
    }

    &__Actions {
      &-Button {
        padding: 1.2rem 8rem;
        justify-self: flex-end;
      }
    }

    &__ChatContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      margin: 3rem 2rem;

      &-Icon {
        margin-right: 0.5rem;
      }
    }
  }
`;
export interface EmailPasswordStepProps {
  handleNext: () => void;
  onChange: (value: unknown, field: keyof FormDataType) => void;
  formData: FormDataType;
}
const schemaEmail = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});
const schemaPassword = Joi.object({
  password: Joi.string().min(6).required(),
});

const validateField = (data: any, schema: any) => {
  const { error } = schema.validate(data);
  return error ? error.details[0].message : "";
};

export const EmailPasswordStep = ({
  handleNext,
  onChange,
  formData,
  ...props
}: EmailPasswordStepProps) => {
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleChange = (field: any, value: any) => {
    onChange(
      {
        ...formData.emailPasswordStep,
        [field]: value,
      },
      "emailPasswordStep"
    );
  };

  const handleSubmit = () => {
    const emailError = validateField(
      { email: formData.emailPasswordStep.email },
      schemaEmail
    );
    const passwordError = validateField(
      { password: formData.emailPasswordStep.password },
      schemaPassword
    );

    setErrors({ email: emailError, password: passwordError });

    if (!emailError && !passwordError) {
      handleNext();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <StyledStep {...props}>
      <div className="Step">
        <div className="Step__Content">
          <TextPresets.TitleH2 className="Step__Content-Title">
            Let's get started!
          </TextPresets.TitleH2>

          <InputPresets.TextInput
            containerProps={{ className: "Step__Content-Input" }}
            placeholder="Email"
            value={formData.emailPasswordStep.email}
            onChange={(e) => handleChange("email", e.target.value)}
            name="email"
          />

          {errors.email && <p style={{ color: "red" }}>{errors.email}</p>}

          <InputPresets.TextInput
            containerProps={{ className: "Step__Content-Input" }}
            placeholder="Password"
            value={formData.emailPasswordStep.password || ""}
            onChange={(e) => handleChange("password", e.target.value)}
            name="password"
            onKeyDown={handleKeyPress}
            type="password"
          />

          {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
        </div>
        <div className="Step__Actions">
          <ButtonPresets.ButtonRoundedFilledPrimary
            className="Step__Actions-Button"
            label="Continue"
            onClick={handleSubmit}
          />
        </div>
      </div>
      <div className="Step__ChatContainer"></div>
    </StyledStep>
  );
};
