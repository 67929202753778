import { FC } from "react";
import HomeScreenView from "./Views/HomeScreenView";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/store";

import { ScreenLoader } from "../../components/UI/Spinners/presets";
import { useGetFiltersByOwner } from "../../hooks/useGetFiltersByOwner";

const HomeScreen: FC = () => {
  const navigate = useNavigate();

  const isLoading = useAppSelector((state) => state.ganalytics.isFetching);
  const { user } = useAppSelector((state) => state.authentication);

  const { filters, isLoading: isLoadingFilter } = useGetFiltersByOwner(
    user!._id
  );

  const generalGanalyticsCampaign = useAppSelector(
    (state) => state.ganalytics.allGanalyticsCampaign
  );

  const onCreateFilter = () => {
    navigate("/filters/upload");
  };

  if (isLoading) {
    return <ScreenLoader />;
  }

  if (isLoadingFilter) {
    return <ScreenLoader />;
  }

  return (
    <>
      {generalGanalyticsCampaign && (
        <HomeScreenView
          ganalytics={generalGanalyticsCampaign}
          onCreateFilter={onCreateFilter}
          filters={filters}
        />
      )}
    </>
  );
};

export default HomeScreen;
