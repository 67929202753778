import { FC, PropsWithChildren } from "react";
import { Styles } from "./AuthLayout.styles";
import logoFiltroo from "../../../assets/logos/logoFiltroo.svg";
import { Outlet } from "react-router-dom";

interface LayoutProps {
  children?: React.ReactNode;
}

const AuthLayout: FC<LayoutProps> = ({ children }: PropsWithChildren) => {
  return (
    <Styles>
      <img src={logoFiltroo} alt="logo" className="Auth__Logo" />
      <Outlet />
      {children}
    </Styles>
  );
};

export default AuthLayout;
